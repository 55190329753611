// Imports
// ------
import styled from 'styled-components';
import { breakup } from '@tackl';
import { Row } from '@waffl';
import { themeStyles } from '@theme';
// Exports
// ------

export const Highlighted = styled.span`
	font-style: italic;
	font-family: ${(props) => props.theme.serif};
`;
export const MainRow = styled(Row)`
	padding-bottom: 4rem;
	// max-height: ${(props) => props.maxHeight}px;
	// padding-top: ${themeStyles.vh};
	${breakup.medium`
		align-items: flex-start;
		padding-top: 0;
		height: ${themeStyles.vh};
		// max-height: auto;
	`}
	${breakup.medium`
		align-items: flex-start;
	`}
`;

export const Canvas = styled.canvas`
	width: 100%;
	height: ${themeStyles.vh};
	position: absolute;
	top: 0;
	left: 0;
`;

export const Jacket = styled.div`
	position: relative;
	width: 100%;
	overflow: hidden;
`;
