// Imports
// ------
import styled from 'styled-components';

// Exports
// ------
export const Jacket = styled.div`
    ${props => props.theme.talign}
    position: fixed;
    pointer-events: none;
    z-index: 999;
    opacity: 0;
    width: max-content;

    button {
        background: ${props => props.theme.bc1};
        color: ${props => props.theme.bc2};
    }
`;
