// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, H1, P } from '@tackl';
import { Column } from '@waffl';

// Exports
// ------
export const SVG = styled.svg`
	opacity: 0;
	height: 100%;
`;

export const LeftColumn = styled(Column)`
	// position: absolute;
	height: calc(var(--vh, 1vh) * 100);
	// top: 0;
	${breakup.smedium`  `}

	${breakup.medium`
	height: auto;
	top: 0;
	margin-top: calc(50vh);
	position: relative;

	bottom: auto;
`}
`;

export const LeftColumnInner = styled.div`
	position: relative;
	// transform: translateY(-100%);
	height: 100%;

	${breakup.medium`
	height: auto;

		transform: translateY(-100%);
		padding-bottom: 2rem;
    `}
`;

//  REM BASED
let fontSizeHuge = 10;
let fontSizeLarge = 8.5;
let fontSizeMedium = 6.5;
let fontSizeSMedium = 6.3;
let fontSizeSmall = 5;
let sSmallYPadding = 6;

// 4rem is the padding of the left column (so texts don't collide)
export const RightColumn = styled(Column)`
	position: absolute;
	top: 0;
	left: 0;
	height: calc(var(--vh, 1vh) * 100);
	// transform: translateY(-100%);
	// top: calc(var(--vh, 1vh) * 100 - ${sSmallYPadding}rem);

	${breakup.medium`
		margin-top: calc(50vh + 4rem);
		position: relative;
		height: auto;
		top: 0;
		bottom: auto;
		bottom: 25%; 
		transform: translateY(-50%);
    `}
`;

export const Title = styled(H1)(
	(props) => css`
		text-transform: uppercase;
		opacity: 1;

		font-size: 12rem;
		font-weight: 300;
		letter-spacing: -5px;
		text-align: ${props.smAlign || 'right'};

		${breakup.medium`
		text-align: ${props.textAlign || 'left'};
		font-size: 12rem;
	`};

		font-size: ${fontSizeSmall}rem;

		${breakup.smedium` font-size: ${fontSizeSMedium}rem; `}
		${breakup.medium` font-size: ${fontSizeMedium}rem; `}
	${breakup.large` font-size: ${fontSizeLarge}rem; `}
	${breakup.huge` font-size: ${fontSizeHuge}rem;  `}
	`
);

export const TopTitle = styled(Title)`
	margin-bottom: 0;
	position: absolute;
	top: calc(${sSmallYPadding}rem * 1.6);
	right: 0;
	height: 1em;

	${breakup.smedium`
		margin-bottom: 30vh;
		top: calc(${sSmallYPadding}rem * 1.4);
	`}

	${breakup.medium`
		position: initial;
		top: initial;
		right: initial;
		margin-bottom: 2rem;
		transform: translateX(5%);
	`}

	${breakup.large`
		transform: translateX(10%);
	`}
`;

export const MiddleTitle = styled(Title)(
	({ isDashed }) => css`
		position: absolute;
		top: calc(100% - ${fontSizeSmall * 1.2}rem - ${sSmallYPadding}rem - 4rem);
		height: ${isDashed ? `calc(1em * 2.2)` : `1em`};
		transform: translateY(-100%);

		${breakup.medium`
			position: initial;
			top: initial;
			transform: none;
		`}
	`
);

export const BottomTitle = styled(Title)`
	height: 1em;
	position: absolute;
	bottom: ${sSmallYPadding}rem;
	right: 0;

	${breakup.smedium`
		margin-right: 2rem;
	`}

	${breakup.medium`
	position: relative;
	bottom: 0;
		margin-right: 5vw;
	`}
`;

export const LargeInfoRight = styled(P)`
	position: absolute;
	bottom: calc(200% + 2rem);
	text-align: left;
	text-transform: none;
	padding-bottom: 3rem;
	max-width: 16em;
`;

export const LargeInfoLeft = styled(P)`
	position: absolute;
	top: 100%;
	text-align: left;
	text-transform: none;
	padding-top: 4rem;
	margin-left: 10rem;
	max-width: 16em;
`;
