// Imports
// ------
import React from 'react';
import Slider from 'react-slick';
import Accolade from './Slide';
import { Row, Column } from '@waffl';

// Styles
// ------
import {
    Jacket,
    Title
} from './styles';

// Component
// ------
class Accolades extends React.PureComponent {
    render() {
        const settings = {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 1000,
            slidesToShow: 4,
            slidesToScroll: 1,
            draggable: true,
            autoplay: true,
            autoplaySpeed: 2000,
            pauseOnHover: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slideToScroll: 1,
                        centerMode: true,
                        centerPadding: `172px`,
                    }
                },
                {
                    breakpoint: 440,
                    settings: {
                        slidesToShow: 1,
                        slideToScroll: 1,
                        centerMode: true,
                        centerPadding: '60px',
                    }
                },
            ]
        };

        return (
            <Jacket borderBottom>
                <Row isExpanded>
                    <Column small={12}>
                        <Title>Accolades</Title>
                    </Column>
                </Row>

                <Slider {...settings}>
                    {this.props.content.map(({ image, imageDark, caption }, i) => (
                        <Accolade
                            key={`${caption}${i}`}
                            image={image}
                            imageDark={imageDark}
                            caption={caption}
                        />
                    ))}
                </Slider>
            </Jacket>
        );
    }
}

export default Accolades;