// Imports
// ------
import React from 'react';
import FormBlock from '@parts/FormBlock';
import ReactHtmlParser from 'react-html-parser';
import { Row, Column } from '@waffl';

// Styles
// ------
import {
    Jacket,
    Title,
    Subtitle,
    Content
} from './styles';

// Component
// ------
const Services = ({ title, content }) => (
    <Jacket padBottom>
        <Row isExpanded isEqual>
            <Column small={12} medium={4} large={6}>
                <Title>{title}</Title>
            </Column>
            <Column small={12} medium={8} large={6}>
                {content.map((block, i) => (
                    <React.Fragment key={`block-${i}`}>
                        {block.__typename === `DatoCmsServiceArea` && (
                            <>
                                <Subtitle large>{block.serviceAreaTitle}:</Subtitle>
                                <Content>{ReactHtmlParser(block.serviceAreaContent)}</Content>
                            </>
                        )}
                    </React.Fragment>
                ))}

                {/* Box for chat */}
                <FormBlock
                    mainTitle="We're always looking to forge effective partnerships — What would you like to do?"
                    optionsQ3
                />
            </Column>
        </Row>
    </Jacket>
);

export default Services;