// Imports
// ------
import React from 'react';
import FormBlock from '@parts/FormBlock';
import { Row, Column } from '@waffl';
import { Parallax } from 'react-scroll-parallax';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Styles
// ------
import {
    Jacket,
    Title,
} from './styles';

// Component
// ------
const Form = ({ optionsQ1 }) => (
    <FormBlock
        subTitle="Tailor the Experience"
        mainTitle="Within Luxury, what is your focus?"
        optionsQ1={optionsQ1}
    />
);

const Plx = ({ optionsQ1 }) => (
    <Parallax y={[50, -50]}>
        <Form optionsQ1={optionsQ1}/>
    </Parallax>
);

const TailoredExperience = ({ title, optionsQ1 }) => {
    const breakpoints = useBreakpoint();

    return (
        <Jacket pad border>
            <Row isExpanded>
                <Column small={12} medium={6}>
                    <Title>{title}</Title>
                </Column>

                <Column small={12} medium={6} large={5}>
                    {breakpoints.medium ? (
                        <Form optionsQ1={optionsQ1} />
                    ) : (
                        <Plx optionsQ1={optionsQ1} />
                    )}
                </Column>
            </Row>
        </Jacket>
    );
}

export default TailoredExperience;