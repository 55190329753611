// Imports
// ------
import React from 'react';
import Marquee from 'react-double-marquee';
import { Row, Column } from '@waffl';
import { formQuestions } from '@states/formQuestions';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { lightDarkMode } from '@states/darkmode';
import { observer } from 'mobx-react';

// Images
// ------
import QuoteIconBlk from '@images/quotemark-blk.gif';
import QuoteIconWht from '@images/quotemark-wht.gif';

// Styles
// ------
import {
    Jacket,
    MarqueeJacket,
    QuoteText,
    QuoteAuthor,
    QuoteImage
} from './styles';

// Component
// ------
const Quote = ({ content }) => {
    const breakpoints = useBreakpoint();

    return (
        <Jacket marBottom borderDouble>
            <Row isExpanded isCollapsed>
                <Column small={12}>
                    {content.map((section, i) => {
                        if (formQuestions.q1Selection === section.name) return (
                            <React.Fragment key={`section-${i}`}>
                                { section.__typename === `DatoCmsCategory` && (
                                    <>
                                        {breakpoints.large ? (
                                            <>
                                                <QuoteImage>
                                                    {lightDarkMode.isDark ? (
                                                        <img src={QuoteIconBlk} alt="Quote Icon" />
                                                    ) : (
                                                        <img src={QuoteIconWht} alt="Quote Icon" />
                                                    )}
                                                </QuoteImage>
                                                <QuoteText>{section.quoteText}</QuoteText>
                                            </>
                                        ) : (
                                            <MarqueeJacket>
                                                <Marquee direction="left" speed={0.15}>
                                                    <QuoteText>{section.quoteText}</QuoteText>
                                                    <QuoteImage>
                                                        {lightDarkMode.isDark ? (
                                                            <img src={QuoteIconBlk} alt="Quote Icon" />
                                                        ) : (
                                                            <img src={QuoteIconWht} alt="Quote Icon" />
                                                        )}
                                                    </QuoteImage>
                                                </Marquee>
                                            </MarqueeJacket>
                                        )}

                                        <QuoteAuthor>{section.quoteAuthor}</QuoteAuthor>
                                    </>
                                )}
                            </React.Fragment>
                        )
                    })}
                </Column>
            </Row>
        </Jacket>
    );
}

export default observer(Quote);