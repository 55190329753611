import {
	BufferGeometry,
	BufferAttribute,
	WebGLRenderTarget,
	Scene,
	OrthographicCamera,
	ShaderMaterial,
	Uniform,
	Matrix3,
	Mesh,
	Vector2,
} from './three';
import fxaa from './shaders/fxaa';
function getFullscreenTriangle() {
	let geometry;
	const vertices = new Float32Array([-1, -1, 0, 3, -1, 0, -1, 3, 0]);
	const uvs = new Float32Array([0, 0, 2, 0, 0, 2]);
	geometry = new BufferGeometry();

	// Added for backward compatibility (setAttribute was added in three r110).
	if (geometry.setAttribute !== undefined) {
		geometry.setAttribute('position', new BufferAttribute(vertices, 3));
		geometry.setAttribute('uv', new BufferAttribute(uvs, 2));
	} else {
		geometry.addAttribute('position', new BufferAttribute(vertices, 3));
		geometry.addAttribute('uv', new BufferAttribute(uvs, 2));
	}

	return geometry;
}

let blur2D = `
vec4 blur12(sampler2D image, vec2 uv, vec2 resolution, vec2 direction) {
  vec4 color = vec4(0.0);
  vec2 off1 = vec2(1.411764705882353) * direction;
  vec2 off2 = vec2(3.2941176470588234) * direction;
  vec2 off3 = vec2(5.176470588235294) * direction;
  color += texture2D(image, uv) * 0.1964825501511404;
  color += texture2D(image, uv + (off1 / resolution)) * 0.2969069646728344;
  color += texture2D(image, uv - (off1 / resolution)) * 0.2969069646728344;
  color += texture2D(image, uv + (off2 / resolution)) * 0.09447039785044732;
  color += texture2D(image, uv - (off2 / resolution)) * 0.09447039785044732;
  color += texture2D(image, uv + (off3 / resolution)) * 0.010381362401148057;
  color += texture2D(image, uv - (off3 / resolution)) * 0.010381362401148057;
  return color;
}
vec4 blur2D(sampler2D image, vec2 uv, vec2 resolution, vec2 direction) {
  vec4 color = vec4(0.0);
  vec2 off1 = vec2(1.3333333333333333) * direction;
  color += texture2D(image, uv) * 0.29411764705882354;
  color += texture2D(image, uv + (off1 / resolution)) * 0.35294117647058826;
  color += texture2D(image, uv - (off1 / resolution)) * 0.35294117647058826;
  return color;
}
`;

export class postprocess {
	constructor(
		renderer,
		size,
		fragmentShader,
		uniforms,
		options = { depthBuffer: false, stencilBuffer: false }
	) {
		this.renderer = renderer;
		this.renderTarget = new WebGLRenderTarget(size.width, size.height, options);

		this.scene = new Scene();
		this.camera = new OrthographicCamera(-1, 1, 1, -1, 0, 1);

		this.uniforms = uniforms;

		this.material = new ShaderMaterial({
			uniforms: {
				uMap: new Uniform(null),
				// For the UVs to sample correctly. We need to multiply them by the texture matrix
				uvTransform: new Uniform(
					new Matrix3().copy(this.renderTarget.texture.matrix)
				),
				...uniforms,
			},
			vertexShader: `
			uniform mat3 uvTransform;
		  varying vec2 vUv;
		  void main(){
			  gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
			  vUv = ( uvTransform * vec3( uv, 1 ) ).xy;
		  }`,
			fragmentShader,
		});
		this.scene.add(new Mesh(getFullscreenTriangle(), this.material));
	}
	setSize(width, height) {
		this.renderTarget.setSize(width, height);
	}
	render(readRT, writeRT) {
		let renderer = this.renderer;
		this.material.uniforms.uMap.value = readRT.texture;
		renderer.setRenderTarget(writeRT);
		renderer.render(this.scene, this.camera);
	}
}
export class AntialiasPostProcess extends postprocess {
	constructor(renderer, size, options) {
		super(
			renderer,
			size,
			`
uniform vec2 resolution;
uniform sampler2D uMap;
varying vec2 vUv;
${fxaa}
void main() {
	gl_FragColor = apply(uMap, 
		vUv * resolution*1.5, 
		resolution *1.5);
}`,
			{
				resolution: { value: new Vector2(size.width, size.height) },
			},
			options
		);
	}
	setSize(width, height) {
		super.setSize(width, height);
		this.material.uniforms.resolution.value.set(width, height);
	}
}
export default class blurPostProcess extends postprocess {
	constructor(renderer, size) {
		super(
			renderer,
			size,
			`
		varying vec2 vUv;
		uniform sampler2D uMap;
		uniform vec2 uDirection;
		uniform vec2 uSize;
		${blur2D}
		  void main(){
			  vec3 color = vec3(1.);
			  color = blur2D(uMap, vUv, vec2(uSize), uDirection).rgb;
			  gl_FragColor = vec4(color, 1.);
		  }
		`,
			{
				uDirection: new Uniform(new Vector2(1, 0)),
				uSize: new Uniform(
					new Vector2(size.width * 0.1 * 2, size.height * 0.1 * 2)
				),
			}
		);
		this.renderTarget2 = new WebGLRenderTarget(size.width, size.height, {
			depthBuffer: false,
			stencilBuffer: false,
		});

		this.blurIterations = 2;
		this.blurSize = 0.2;
		// Because the iterations should always be 2
		this.bluredRenderTarget = this.renderTarget2;
	}

	setSize(width, height) {
		super.setSize(width, height);
		this.renderTarget2.setSize(width, height);
		this.uniforms.uSize.value.set(width * 0.1 * 2, height * 0.1 * 2);
	}
	render() {
		let renderer = this.renderer;
		let initialRT = this.renderer.getRenderTarget();
		let readRT = initialRT;
		let writeRT = this.renderTarget;
		let blurIterations = this.blurIterations;
		let direction = this.material.uniforms.uDirection.value;

		let nextWriteRT = this.renderTarget2;
		for (let i = 0; i < blurIterations; i++) {
			super.render(readRT, writeRT);
			// this.material.uniforms.uMap.value = readRT.texture;

			// renderer.setRenderTarget(writeRT);
			// renderer.render(this.scene, this.camera);

			let x = i % 2;
			let size = this.blurSize;

			if (x === 0) {
				direction.set(size, 0);
			} else {
				direction.set(0, size);
			}
			readRT = writeRT;
			writeRT = nextWriteRT;
			// This makes it so the previous writeRT is the nextWrite
			// This needs to be done after setting the new readRT to make it work.
			nextWriteRT = readRT;
			// this.renderer.clearDepth();
		}

		// this.
		// this.material.uniforms.uMap.value = writeRT.texture;

		// renderer.setRenderTarget(null);
		// renderer.render(this.scene, this.camera);
	}
}
