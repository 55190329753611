// Imports
// ------
import React from 'react';
import Marquee from 'react-double-marquee';
import Cursor from '@parts/Cursors/ItPolicy';
import Button from '@parts/Button';
import { lightDarkMode } from '@states/darkmode';
import { themeStyles } from '@theme';
import { Row, Column } from '@waffl';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Styles
// ------
import {
    Jacket,
    MarqueeJacket,
    Link,
    ContentJacket,
    Text,
    ButtonJacket
} from './styles';

// Exports
// ------
const Desktop = ({ link }) => (
    <>
        <Link to={link} cover direction="up" bg={lightDarkMode.isDark ? themeStyles.bc2 : themeStyles.bc1} className="policyview">
            <MarqueeJacket>
                <Marquee direction="left" speed={0.1}>
                    <ContentJacket>
                        <Text>Information Security Policy</Text>
                        <Button type="button" extraPad>View Here</Button>
                        <Text>Information Security Policy</Text>
                        <Button type="button" extraPad>View Here</Button>
                    </ContentJacket>
                </Marquee>
            </MarqueeJacket>
        </Link>
        <Cursor />
    </>
);

const Mobile = ({ link }) => (
    <MarqueeJacket>
        <Marquee direction="left">
            <ContentJacket>
                <Text mobile>Information Security Policy</Text>
                <Text mobile>Information Security Policy</Text>
            </ContentJacket>
        </Marquee>
        <ButtonJacket>
            <Button type="link" to={link} className="fluid">View Here</Button>
        </ButtonJacket>
        <Marquee direction="right">
            <ContentJacket>
                <Text mobile>Information Security Policy</Text>
                <Text mobile>Information Security Policy</Text>
            </ContentJacket>
        </Marquee>
    </MarqueeJacket>
);


const ItPolicy = () => {
    const breakpoints = useBreakpoint();
    const link = `/information-security-policy/`;

    return (
        <Jacket borderDouble>
            <Row isExpanded isCollapsed>
                <Column small={12}>
                    {breakpoints.medium ? <Mobile link={link} /> : <Desktop link={link} />}
                </Column>
            </Row>
        </Jacket>
    );
}

export default ItPolicy;