// Imports
// ------
import React, { useEffect, useRef } from 'react';
import Slide from './Slide';
import { Row, Column } from '@waffl';

// Styles
// ------
import {
    Jacket,
    Title,
    Text,
    Slider
} from './styles';

// Component
// ------
const Database = ({ content, title, text }) => {
    const ticker = useRef();

    let tickerSpeed = 2;

    let flickity = null;
    let isPaused = false;

    useEffect(() => {
        const Flickity = require("flickity");

        const slider = ticker.current;

        const update = () => {
            if (isPaused) return;
            if (flickity.slides) {
                flickity.x = (flickity.x - tickerSpeed) % flickity.slideableWidth;
                flickity.selectedIndex = flickity.dragEndRestingSelect();
                flickity.updateSelectedSlide();
                flickity.settle(flickity.x);
            }
            typeof window !== 'undefined' && window.requestAnimationFrame(update);
        };
    
        const pause = () => {
            isPaused = true;
        };
        
        const play = () => {
            if (isPaused) {
                isPaused = false;
                typeof window !== 'undefined' && window.requestAnimationFrame(update);
            }
        };
    
        flickity = new Flickity(slider, {
            autoPlay: false,
            prevNextButtons: false,
            pageDots: false,
            draggable: true,
            wrapAround: true,
            selectedAttraction: 0.015,
            friction: 0.25,
        });
        
        flickity.x = 0;
    
        slider.addEventListener('mouseenter', pause, false);
        slider.addEventListener('focusin', pause, false);
        slider.addEventListener('mouseleave', play, false);
        slider.addEventListener('focusout', play, false);
    
        flickity.on('dragStart', () => {
            isPaused = true;
        });
    
        update();
    }, []);

    return (
        <Jacket pad borderBottom>
            <Row isExpanded>
                <Column small={12} medium={6}>
                    <Title>{title}</Title>
                </Column>
                <Column small={12} medium={5} large={3}>
                    <Text>{text}</Text>
                </Column>
            </Row>

            <Slider ref={ticker}>
                {content.map((block, i) => (
                    <Slide
                        key={`db-` + i}
                        image={block.node.image.fluid}
                        client={block.node.client}
                        desc={block.node.description}
                        url={block.node.url}
                    />
                ))}
            </Slider>
        </Jacket>
    );
}

export default Database;