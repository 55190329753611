// Imports
// ------
import styled from 'styled-components';
import { breakup, Section, H2 } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)`
    margin-bottom: 3.6rem;

    ${breakup.large` margin-bottom: 0; `}
`;

export const Title = styled(H2)`
    margin: 0 0 3.6rem;

    ${breakup.large` margin: 0 0 6rem; `}
`;