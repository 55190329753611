// Imports
// ------
import React from 'react';
import SEO from '@parts/Seo';
import Chat from '@parts/Chat';
import Intro from '@parts/Intro';
import TailoredExperience from '@parts/TailoredExperience';
import About from '@parts/About';
import Services from '@parts/Services';
import Quote from '@parts/Quote';
import Focus from '@parts/Focus';
import ItPolicy from '@parts/ItPolicy';
import Database from '@parts/Database';
import Team from '@parts/Team'
import NetworkExperts from '@parts/NetworkExperts';
import Clientele from '@parts/Clientele';
import Accolades from '@parts/Accolades';
import Fibre from '@parts/Fibre';
import Company from '@parts/Company';
import Footer from '@parts/Footer';
import { graphql } from 'gatsby';

// Component
// ------
const IndexPage = ({ data }) => (
    <>
        <SEO
            title={data.home.seo.title}
            description={data.home.seo.description}
            image={data.home.seo.image}
            schema={data.home.schema}
        />

        <Intro />

        <TailoredExperience
            title={data.home.title}
            optionsQ1={data.home.tailorExperienceQ1}
        />

        <About
            content={data.home.tailorExperienceQ1}
            optionsQ2={data.home.tailorExperienceQ2}
        />

        <Clientele categories={data.home.tailorExperienceQ1} />

        <Services
            title={data.home.servicesTitle}
            content={data.home.servicesContent}
        />

        <Quote content={data.home.tailorExperienceQ1} />

        <Team content={data.team.edges} />

        <Accolades content={data.home.accolades} />

        <Focus
            title={data.home.ourFocusTitle}
            content={data.home.ourFocusContent}
        />

        <ItPolicy />

        <NetworkExperts
            tech={data.home.tech}
            design={data.home.design}
            res={data.home.res}
        />
        
        <Fibre
            content={data.home.fibreDescription}
            label={data.home.fibreLinkLabel}
            link={data.home.fibreLinkUrl}
            image1={data.home.fibreImage1}
            image2={data.home.fibreImage2}
        />

        <Database
            title={data.home.databaseTitle}
            text={data.home.databaseDescription}
            content={data.db.edges}
        />

        <Company
            title={data.home.companyTitle}
            content={data.home.companyContentNode.childMarkdownRemark.html}
        />

        <Footer
            legals={data.legals.edges}
            blurb={data.global.footerBlurbNode.childMarkdownRemark.html}
            locations={data.global.locations}
        />

        <Chat />
    </>
);

export default IndexPage;


// GraphQL
// ------
export const query = graphql`
    query homeQuery {
        home: datoCmsHomepage {
            schema

            seo {
                title
                description
                image {
                    url
                }
            }

            title
            tailorExperienceQ1 {
                __typename
                
                ... on DatoCmsCategory {
                    name
                    content
                    quoteText
                    quoteAuthor
                    clientList
                }
            }
            tailorExperienceQ2 {
                __typename
                
                ... on DatoCmsQ2category {
                    name
                }
            }

            ourFocusTitle
            ourFocusContent

            databaseTitle
            databaseDescription

            servicesTitle
            servicesContent {
                __typename
                
                ... on DatoCmsServiceArea {
                    serviceAreaTitle
                    serviceAreaContent
                }
            }

            tech: noeTechnology {
                __typename

                ... on DatoCmsNoePost {
                    image {
                        alt
                        fluid(maxWidth: 500) {
                            ...GatsbyDatoCmsFluid_noBase64
                        }
                    }
                    title
                    description
                }
            }

            design: noeDesign {
                __typename

                ... on DatoCmsNoePost {
                    image {
                        alt
                        fluid(maxWidth: 500) {
                            ...GatsbyDatoCmsFluid_noBase64
                        }
                    }
                    title
                    description
                }
            }

            res: noeResearch {
                __typename

                ... on DatoCmsNoePost {
                    image {
                        alt
                        fluid(maxWidth: 500) {
                            ...GatsbyDatoCmsFluid_noBase64
                        }
                    }
                    title
                    description
                }
            }

            accolades {
                __typename

                ... on DatoCmsAccolade {
                    image {
                        alt
                        fluid(maxWidth: 340) {
                            ...GatsbyDatoCmsFluid_noBase64
                        }
                    }
                    imageDark {
                        alt
                        fluid(maxWidth: 340) {
                            ...GatsbyDatoCmsFluid_noBase64
                        }
                    }
                    caption
                }
            }

            companyTitle
            companyContentNode {
                childMarkdownRemark {
                    html
                }
            }

            fibreDescription
            fibreLinkLabel
            fibreLinkUrl
            fibreImage1 {
                alt
                url
            }
            fibreImage2 {
                alt
                url
            }
        }

        db: allDatoCmsTheDatabase(sort: { fields: position }) {
            edges {
                node {
                    client
                    description
                    url
                    image {
                        alt
                        width
                        height
                        fluid(maxWidth: 450) {
                            ...GatsbyDatoCmsFluid_noBase64
                        }
                    }
                }
            }
        }

        team: allDatoCmsTheTeam(sort: { fields: position }) {
            edges {
                node {
                    name
                    job: jobTitle
                    desc: shortDescription
                    social: linkedinUrl
                    photo {
                        alt
                        fluid(maxWidth: 400) {
                            ...GatsbyDatoCmsFluid_noBase64
                        }
                    }
                }
            }
        }

        legals: allDatoCmsLegal(filter: { slug: { nin: ["information-security-policy", "cookie-policy"] }}) {
            edges {
                node {
                    title
                    slug
                }
            }
        }

        global: datoCmsGlobalStuff {
            footerBlurbNode {
                childMarkdownRemark {
                    html
                }
            }

            locations {
                __typename
                ... on DatoCmsLocation {
                    locationName
                }
            }
        }
    }
`;
