// Imports
// ------
import React from 'react';
import NoeSection from './NoeSection';
import { Row, Column } from '@waffl';
import { formQuestions } from '@states/formQuestions';
import { observer } from 'mobx-react';

// Styles
// ------
import {
    Jacket,
    Title,
} from './styles';

// Component
// ------
const NetworkExperts = ({ tech, design, res }) => (
    <Jacket marTop padBottom>
        <Row isExpanded>
            <Column small={12}>
                <Title>Network of Experts</Title>
            </Column>
        </Row>
        
        <Row isExpanded isCollapsedOnLarge>
            <Column small={12}>
                {formQuestions.q1Selection == "Manufacturing" ? (
                    <>
                        <NoeSection
                            title="Technology"
                            content={tech}
                            speed={2}
                        />
                        <NoeSection
                            title="Design"
                            content={design}
                            speed={2.5}
                        />
                        <NoeSection
                            title="Research"
                            content={res}
                            speed={3}
                            isLast
                        />
                    </>
                ) : (
                    formQuestions.q2Selection == "Enterprise Technology" && formQuestions.q1Answered === false ? (
                        <>
                            <NoeSection
                                title="Technology"
                                content={tech}
                                speed={2}
                            />
                            <NoeSection
                                title="Research"
                                content={res}
                                speed={2.5}
                            />
                            <NoeSection
                                title="Design"
                                content={design}
                                speed={3}
                                isLast
                            />
                        </>
                    ) : (
                        formQuestions.q2Selection == "Strategic Consulting" && formQuestions.q1Answered === false ? (
                            <>
                                <NoeSection
                                    title="Research"
                                    content={res}
                                    speed={2}
                                />
                                <NoeSection
                                    title="Technology"
                                    content={tech}
                                    speed={2.5}
                                />
                                <NoeSection
                                    title="Design"
                                    content={design}
                                    speed={3}
                                    isLast
                                />
                            </>
                        ) : (
                            <>
                                <NoeSection
                                    title="Design"
                                    content={design}
                                    speed={2}
                                />
                                <NoeSection
                                    title="Technology"
                                    content={tech}
                                    speed={2.5}
                                />
                                <NoeSection
                                    title="Research"
                                    content={res}
                                    speed={3}
                                    isLast
                                />
                            </>
                        )
                    )
                )}
            </Column>
        </Row>
    </Jacket>
)

export default observer(NetworkExperts);