// Imports
// ------
import styled, { css } from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { breakup, Section } from '@tackl';

// Exports
// ------
export const Link = styled(AniLink)`
    position: relative;
    display: block;
`;

export const ContentJacket = styled.div`
    position: relative;
    display: inline-block;
    padding: .5rem 0;
`;

export const Text = styled.span`
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 3.6rem;
    margin-left: 3.6rem;

    font-family: ${props => props.theme.heading};
    font-size: 3.6rem;
    font-weight: 200;
    text-transform: uppercase;
    color: ${props => props.theme.bc1};

    ${props => props.mobile && css`
        padding-left: 3.6rem;
        margin: 2.4rem 0;

        font-size: 2.2rem;

        &:first-child { margin-right: 3.6rem; }

        &:before {
            content: '';
            position: absolute;
            top: 50%; left: 0;
            transform: translateY(-50%);

            width: 6px;
            height: 6px;
            border-radius: 100%;
            background: ${props => props.theme.bc1};
        }
    `}
`;

export const ButtonJacket = styled.div`
    display: block;
    padding: 0 2.4rem;
`;

export const MarqueeJacket = styled.div`
    transition: all 1s ease;
    position: relative;
    display: block;
    width: 100%;
    white-space: nowrap;

    ${breakup.large`
        padding: 8rem 0;
    `}
`;


export const Jacket = styled(Section)`
    position: relative;
    overflow: hidden;
    
    &:hover {
        ${MarqueeJacket} {
            ${breakup.large`
                pointer-events: none;
                filter: blur(1.2rem);
            `}
        }
    }
`;