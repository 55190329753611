// Imports
// ------
import React from 'react';
import Img from 'gatsby-image';

// Styles
// ------
import {
    Jacket,
    Image,
    Content,
    Title,
    Description
} from './styles';

// Component
// ------
const Slide = ({ title, image, desc }) => (
    <Jacket>
        <Image>
            <Img fluid={image} alt={image.alt} loading="eager" />
        </Image>
        <Content>
            <Title>{title}</Title>
            <Description>{desc}</Description>
        </Content>
    </Jacket>
);

export default Slide;

