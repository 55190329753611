// Imports
// ------
import React from 'react';
import Img from 'gatsby-image';

// Styles
// ------
import {
    Jacket,
    Sweater,
    Item,
    Picture,
    Content,
    Client,
    Description
} from './styles';

// Component
// ------
const Slide = ({ client, image, desc, url }) => ( 
    <>
        {url ? (
            <Jacket
                href={url}
                rel="noopener noreferrer"
                aria-label={`Link through to ${url}`}
                target="_blank"
            >
                <Sweater>
                    <Item>
                        <Picture>
                            <Img fluid={image} alt={image.alt} loading="eager" />
                        </Picture>
                        <Content>
                            <Client>{client}</Client>
                            <Description>{desc}</Description>
                        </Content>
                    </Item>
                </Sweater>
            </Jacket>
        ) : (
            <Sweater>
                <Item>
                    <Picture>
                        <Img fluid={image} alt={image.alt} loading="eager" />
                    </Picture>
                    <Content>
                        <Client>{client}</Client>
                        <Description>{desc}</Description>
                    </Content>
                </Item>
            </Sweater>
        )}
    </>
);

export default React.memo(Slide);

