// Imports
// ------
import React, { useState } from 'react';
import Icon from '@icons';
import Button from '@parts/Button';
import { Chat } from '@states/chat';
import { animated, Transition } from 'react-spring/renderprops'
import { formQuestions } from '@states/formQuestions';
import { observer } from 'mobx-react';

// Styles
// ------
import {
    Jacket,
    Content,
    Sub,
    Main,
    OptionsList,
    Option,
    OptionButton,
    Confirmation,
    ConfirmationLeft,
    ConfirmationRight,
    Label,
    Reset,
    ButtonList
} from './styles';

// Component
// ------
const FormBlock = ({subTitle, mainTitle, optionsQ1, optionsQ2 }) => {
    const [ show, setShow ] = useState(false);
    const optionsToUse = (optionsQ1 || optionsQ2);

    function handleSelection1(e) {
        // Set the selection in global state
        formQuestions.q1Selection = e.target.innerHTML

        // Set the question as answered
        formQuestions.q1Answered = true;
        setShow(true);
    }

    function handleSelection2(e) {
        // Set the selection in global state
        formQuestions.q2Selection = e.target.innerHTML

        // Set the question as answered
        formQuestions.q2Answered = true;
        setShow(true);
    }

    function functionRunner(e) {
        if (optionsQ1) {
            handleSelection1(e);
        } else {
            handleSelection2(e);
        }
    }

    function q1Reset() {
        setShow(false)
        formQuestions.q1Selection = "General Interest";
        formQuestions.q1Answered = false;
    }

    function q2Reset() {
        setShow(false)
        formQuestions.q2Selection = "";
        formQuestions.q2Answered = false;
    }

    function resetRunner(e) {
        if (optionsQ1) {
            q1Reset(e);
        } else {
            q2Reset(e);
        }
    }

    function handleRequestDeck() {
        Chat.isActive = !Chat.isActive;
        Chat.requestDeck = true;
        Chat.requestProposal = false;
        Chat.requestExpert = false;
        Chat.requestIndividual = false;
    }

    function handleRequestProposal() {
        Chat.isActive = !Chat.isActive;
        Chat.requestDeck = false;
        Chat.requestProposal = true;
        Chat.requestExpert = false;
        Chat.requestIndividual = false;
    }

    function handleRequestExpoert() {
        Chat.isActive = !Chat.isActive;
        Chat.requestDeck = false;
        Chat.requestProposal = false;
        Chat.requestExpert = true;
        Chat.requestIndividual = false;
    }

    return (
        <Jacket>
            <Content>
                <Sub>{subTitle}</Sub>
                <Main uppercase>{mainTitle}</Main>
            </Content>
            
            {optionsToUse && (
                <OptionsList isHidden={show} split={optionsQ1}>
                    {optionsToUse.map(({ name }, i) => {
                        if(name !== "General Interest") return (
                            <React.Fragment key={`${name}-${i}`}>
                                <Option>
                                    <span>+ </span>
                                    <OptionButton
                                        type="button"
                                        onClick={(e) => functionRunner(e)}
                                        aria-label={`${name} Button`}
                                    >{name}</OptionButton>
                                </Option>
                            </React.Fragment>
                        )
                    })}
                </OptionsList>
            )}

            {!optionsToUse && (
                <ButtonList>
                    <Button fluid aria="Request a capabilities deck" type="button" onClick={() => handleRequestDeck()}>Request a capabilities deck</Button>
                    <Button fluid aria="Request a proposal" type="button" onClick={() => handleRequestProposal()}>Request a proposal</Button>
                    <Button fluid aria="Connect with an expert" type="button" onClick={() => handleRequestExpoert()}>Connect with an expert</Button>
                </ButtonList>
            )}

            <Transition
                items={show}
                from={{
                    opacity: 0,
                    position: 'absolute',
                    zIndex: 12,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    transform: 'opacity(0)',
                    transformOrigin: `center bottom`
                }}
                enter={{
                    opacity: 1,
                    transform: 'opacity(1)'
                }}
                leave={{
                    opacity: 0,
                    transform: 'opacity(0)'
                }}
            >
                {show => show && (props => (
                    <animated.div style={props}>
                        <Confirmation>
                            <ConfirmationLeft>
                                <Label>Thanks</Label>
                                <Reset onClick={(e) => resetRunner(e)} aria-label="Reset Button">
                                    <Icon type="reset" />
                                </Reset>
                            </ConfirmationLeft>
                            <ConfirmationRight>
                                <Label right>We've reordered the site with a personalized content structure. Scroll down to learn more!</Label>
                            </ConfirmationRight>
                        </Confirmation>
                    </animated.div>
                ))}
            </Transition>
        </Jacket> 
    );
}

export default observer(FormBlock);