// Imports
// ------
import React, { useRef } from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import Icon from '@icons';
import { Row, Column } from '@waffl';
import { lightDarkMode } from '@states/darkmode';
import { observer } from 'mobx-react';

// Styles
// ------
import {
    Jacket,
    ContentJacket,
    Text,
    TextLinkJacket,
    TextLink,
    Bottom,
    Books,
    Book,
} from './styles';

// Component
// ------
const Fibre = ({ image1, image2, link, content, label }) => {
    const closedBook = useRef();
    const openBook = useRef();

    function onEnterViewport() {
        [closedBook, openBook].forEach((el) => { el.current.classList.add('visible') });
    }

    return (
        <Jacket padTop borderBottom>
            <Books>
                <Book isOpen src={image1.url} alt="Book - Open" ref={closedBook}/>
                <Book src={image2.url} alt="Book - Closed" ref={openBook}/>
            </Books>

            <ContentJacket>
                <Row isExpanded>
                    <Column small={12} medium={6} large={4} mpad>
                        <Text>
                            <span>{content}</span>
                        </Text>

                        <ScrollTrigger onEnter={() => onEnterViewport()} />

                        <Bottom>
                            <TextLinkJacket isDark={lightDarkMode.isDark}>
                                <TextLink href={link} target="_blank" rel="noreferrer noopener">{label}<Icon type="btn-arrow"/></TextLink>
                            </TextLinkJacket>
                        </Bottom>
                    </Column>
                </Row>
            </ContentJacket>
        </Jacket>
    );
}

export default observer(Fibre);