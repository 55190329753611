// Imports
// ------
import React, { useRef, useEffect } from 'react';
import Img from 'gatsby-image';
import gsap from 'gsap';
import { Chat } from '@states/chat';
import { Row, Column } from '@waffl';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Styles
// ------
import {
    Jacket,
    Content,
    Title,
    TitleJacket,
    Job,
    Desc,
    ChatButton,
    Button,
    MobilePhoto,
    MobileDesc,
    PhotoCursor
} from './styles';

// Component
// ------
const Member = ({ name, job, desc, social, photo }) => {
    const cursorRef = useRef();
    const containerRef = useRef();
    const breakpoints = useBreakpoint();

    function activateCursor() {
        const cursor = cursorRef.current;
        const container = containerRef.current;

        function moveCircle(e) {
            gsap.to(cursor, 0.3, {
                css: {
                    zIndex: 1,
                    opacity: 1,
                    left: e.clientX,
                    top: e.clientY,
                }
            });
        }

        // Container
        // ------
        container.addEventListener('mouseover', () => {
            gsap.to(cursor, 0.4, { scale: 1, autoAlpha: 1 })
            container.addEventListener('mousemove', moveCircle);
        });

        container.addEventListener('mouseout', () => {
            gsap.to(cursor, 0.4, { scale: 0.1, autoAlpha: 0 })
        });
    }

    function handleRequestIndividual() {
        Chat.requestIndividual = true;
        Chat.personName = name;
        Chat.personImage = photo;

        Chat.isActive = !Chat.isActive;
    }

    useEffect(() => {
        if (window.matchMedia('(min-width: 1024px)').matches) {
            activateCursor();
        }
    }, []);

    return (
        <Jacket ref={containerRef}>
            <Row isExpanded isEqual>
                {/* Title */}
                <Column small={8} medium={5} large={6}>
                    <Content isStart>
                        <Row isExpanded isCollapsed>
                            <Column small={3} smedium={2} medium={2} hideOnLarge>
                                { breakpoints.medium ? (
                                    <MobilePhoto>
                                        <Img fluid={photo.fluid} alt={photo.alt} />
                                    </MobilePhoto>
                                ) : null}
                            </Column>

                            <Column small={9} smedium={10} medium={10} large={12}>
                                <TitleJacket>
                                    <Title>{name}</Title>
                                    <Job fontAlt>{job}</Job>
                                </TitleJacket>
                            </Column>
                        </Row>
                    </Content>
                </Column>

                {/* Details */}
                <Column large={4} medium={5} className="hide-on-mobile">
                    <Content>
                        {breakpoints.smedium ? null : <Desc>{desc}</Desc>}
                    </Content>
                </Column>

                {/* Contact */}
                <Column small={4} medium={2} large={2}>
                    <Content isEnd>
                        <ChatButton onClick={() => handleRequestIndividual()} aria-label="Message">
                            <span>Message</span>
                        </ChatButton>
                        <Button
                            target="_blank"
                            rel="noopener noreferrer"
                            href={social}
                        >
                            <span>LinkedIn</span>
                        </Button>
                    </Content>
                </Column>
            </Row>
            {breakpoints.medium ? <MobileDesc>{desc}</MobileDesc> : null}

            {breakpoints.large ? null : (
                <PhotoCursor ref={cursorRef}>
                    <Img fluid={photo.fluid} alt={photo.alt} />
                </PhotoCursor>
            )}
        </Jacket>
    );
}

export default React.memo(Member);