// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, ListItem, P } from '@tackl';
import { emStyles } from '@type';

// Exports
// ------
export const Content = styled.div`
    position: relative;
    z-index: 12;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: ${props => props.isEnd ? `flex-end` : `flex-start`};
    height: 100%;
    pointer-events: none;

    ${props => props.isStart && css`
        flex-flow: row;
        justify-content: flex-start;

        ${breakup.medium`
            align-items: center;
        `}

        ${breakup.large`
            flex-flow: column;
            justify-content: center;
            align-items: flex-start;
        `}
    `}
`;


export const TitleJacket = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    width: auto;
`;


export const Title = styled(P)``;

export const Job = styled(P)``;

export const Desc = styled(P)``;

export const MobileDesc = styled(P)`
    margin: 2.4rem 1.2rem 0;
    display: block;

    ${breakup.medium` display: none; `}
`;

const sharedButtonStyles = css`
    ${emStyles}
    display: inline-block;
    position: relative;
    z-index: 99999999;
    pointer-events: all;
    background: ${props => props.theme.bc2};
    padding: 0;
    margin: 0 0 1.2rem 0;
    /* mix-blend-mode: difference; */

    &:last-child { margin-bottom: 0; }

    &:hover {
        cursor: pointer;

        &:after {
            transform: scaleY(1);
        }

        span {
            color: ${props => props.theme.bc2};   
        }
    }

    span {
        position: relative;
        z-index: 3;
        display: block;
        color: ${props => props.theme.bc1};   
    }

    &:after {
        content: '';
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
        z-index: 2;
        background: ${props => props.theme.bc1};

        transform: scaleY(0.05);
        transform-origin: center bottom;
        transition: all .25s ease;
    }
`;

export const Button = styled.a`
    ${sharedButtonStyles}
`;

export const ChatButton = styled.button`
    ${sharedButtonStyles}
`;

export const MobilePhoto = styled.div`
    position: relative;
    width: 3.2rem !important;
    margin-right: 2.4rem;

    ${breakup.medium` width: 8rem; `}
    ${breakup.large` display: none;  `}
`;


export const PhotoCursor = styled.div`
    position: fixed;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    z-index: 12;

    margin-left: 3.6rem;
    margin-top: 2.4rem;
    width: 32rem;
    height: auto;
    opacity: 0;
    pointer-events: none;
`;


export const Jacket = styled(ListItem)`
    position: relative;
    padding: 2.4rem 0;
    overflow: visible;

    ${breakup.medium` padding: 1.2rem 0; `}
    ${breakup.large` padding: 4.8rem 0; `}

    &:after {
        transition: all .4s ease-in-out;
        content: "";
        position: absolute;
        left: 1.2rem; right: 1.2rem; top: 0;
        height: 1px;
        background: ${props => props.theme.bc1};

        ${breakup.large` left: 1.8rem; right: 1.8rem; `}
    }

    &:last-child {
        &:before {
            content: "";
            position: absolute;
            left: 1.2rem; right: 1.2rem; bottom: 0;
            height: 1px;
            background: ${props => props.theme.bc1};

            ${breakup.large` left: 1.8rem; right: 1.8rem; `}
        }
    }

    .hide-on-mobile {
        display: none;
        ${breakup.medium` display: flex; `}
    }

    &:hover {
        ${TitleJacket},
        ${Desc} {
            opacity: 0.5;
        }
    }
`;