// Imports
// ------
import React from 'react';
import Member from './Member';
import { Row, Column } from '@waffl';

// Styles
// ------
import {
    Jacket,
    Title,
    Sweater
} from './styles';

// Component
// ------
const Team = ({ content }) => (
    <Jacket padBottom>
        <Row isExpanded>
            <Column small={12}>
                <Title>Team</Title>
            </Column>
        </Row>

        <Sweater>
            {content.map((team) => (
                <Member
                    key={`${team.node.name}${team.node.job}`}
                    name={team.node.name}
                    job={team.node.job}
                    desc={team.node.desc}
                    photo={team.node.photo}
                    social={team.node.social}
                />
            ))}
        </Sweater>
    </Jacket>
);

export default Team;