// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, P, Em } from '@tackl';

// Exports
// ------
const column = 8.333;

export const Jacket = styled.div(props => css`
    position: relative;
    display: block;
    margin-top: 2.4rem;
    padding-left: calc(${props.theme.gutterSmall} / 2);
    padding-right: calc(${props.theme.gutterSmall} / 2);
    width: ${column * 10}vw;

    ${breakup.medium`
        width: ${column * 6}vw; 
    `}

    ${breakup.large`
        padding-left: calc(${props.theme.gutterLarge} / 2);
        padding-right: calc(${props.theme.gutterLarge} / 2);
        width: ${column * 3}vw;
    `}
`);

export const Image = styled.div`
    margin-bottom: 1.2rem;

    img { opacity: 1 !important; }
`;

export const Content = styled.div`
    position: relative;
`;


export const Title = styled(P)`
    display: block;
    margin-bottom: 1.2rem;
`;

export const Description = styled(Em)` opacity: 0.5; `;