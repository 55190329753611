// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Em } from '@tackl';

// Exports
// ------
export const Jacket = styled.a`
    display: block;
`;

const column = 8.333;

export const Sweater = styled.div(props => css`
    display: block;
    margin-top: 6rem;
    width: ${column * 10}vw;
    padding-left: calc(${props.theme.gutterSmall} / 2);
    padding-right: calc(${props.theme.gutterSmall} / 2);

    ${breakup.medium`
        width: ${column * 6}vw; 
    `}

    ${breakup.large`
        padding-left: calc(${props.theme.gutterLarge} / 2);
        padding-right: calc(${props.theme.gutterLarge} / 2);
        width: ${column * 3}vw;
    `}
`);

export const Picture = styled.div`
    margin-bottom: 1.2rem;
    overflow: hidden;

    img {
        opacity: 1 !important;
    }
`;

export const Content = styled.div`
    position: relative;
`;


export const Client = styled(Em)`
    margin-bottom: 1.2rem;
    
    ${breakup.large`
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        opacity: 0.5;
        transition: opacity 0.4s ease;
    `}
`;

export const Description = styled(Em)`
    display: none;
    transition: opacity 0.4s ease;

    ${breakup.large`
        display: block;
        opacity: 0;
    `}
`;

export const Item = styled.div`
    ${breakup.large`
        &:hover {
            ${Client} { opacity: 0; }
            ${Description} { opacity: 0.5; }
        }
    `}
`;