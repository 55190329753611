// Imports
// ------
import React from 'react';
import { Helmet } from 'react-helmet';

// Component
// ------
const SEO = ({ title, description, image, schema }) => {
    return (
        <Helmet title={title}>
            <html lang="en" />
            <meta name="description" content={description} />

            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            {image && (<meta property="og:image" content={image.url} />)}

            <script type="application/ld+json">{JSON.stringify(schema)}</script>
        </Helmet>
    );
}

export default React.memo(SEO);