// Imports
// ------
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import FormBlock from '@parts/FormBlock';
import { Row, Column } from '@waffl';
import { formQuestions } from '@states/formQuestions';
import { observer } from 'mobx-react';

// Styles
// ------
import {
    Jacket,
    Title,
    Content
} from './styles';

// Component
// ------
const About = ({ content, optionsQ2 }) => (
    <Jacket pad borderBottom marBottom>
        <Row isExpanded isEqual>
            <Column small={12} medium={4} large={6}>
                <Title>About Liquid Crystal</Title>
            </Column>

            <Column small={12} medium={8} large={6}>
                {content.map((section, i) => {
                    if (formQuestions.q1Selection === section.name) return (
                        <React.Fragment key={`section-${i}`}>
                            { section.__typename === `DatoCmsCategory` && (
                                <Content>{ReactHtmlParser(section.content)}</Content>
                            )}
                        </React.Fragment>
                    )
                })}
                
                <FormBlock
                    subTitle="Tailor the Experience"
                    mainTitle="What type of digital solution is most important to you?"
                    optionsQ2={optionsQ2}
                />
            </Column>
        </Row>
    </Jacket>
);

export default observer(About);