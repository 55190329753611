// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Em } from '@tackl';

// Exports
// ------
export const Image = styled.div`
    position: relative;
    display: block;
`;

export const Jacket = styled.div`
    padding: 2.4rem;

    ${breakup.medium` padding: 4.8rem; `}
    ${breakup.large` padding: 7.2rem; `}
`;

export const Caption = styled(Em)(props => css`
    margin-top: 2.4rem;
    text-align: center;
    text-transform: none;
    font-family: ${props.theme.serif};
    font-weight: ${props.theme.bookItalic};
    font-style: italic;
    opacity: 0.5;

    ${breakup.medium` padding: 4.8rem; `}
`);