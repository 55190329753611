// Imports
// ------
import React from 'react';
import Icon from '@icons';
import { Column, Row } from '@waffl';
import { BreakPoint } from '../Breakpoint';

// Styles
// ------
import { BottomText, TopText, ScrollJacket } from './styles';
import { Highlighted } from '../styles';

// Component
// ------
export const PostIntro = React.forwardRef(
	({ isVisible }, ref) => {
		let { scrollArrowRef, p0Ref, p1Ref } = ref;
		return (
			<Row isExpanded style={{ zIndex: 100 }}>
				<Column small={12} smedium={6}>
					<BreakPoint size='large' down>
						<TopText ref={p0Ref} style={{ opacity: isVisible ? 1 : 0 }}>
							Digital <Highlighted>craftsmanship</Highlighted> is the
							contemporary trade of our society.
						</TopText>
					</BreakPoint>
				</Column>
				<Column small={12} smedium={6}>
					<BreakPoint size='large' down>
						<BottomText ref={p1Ref} style={{ opacity: isVisible ? 1 : 0 }}>
							To master it is the key to unlocking the potential for{' '}
							<Highlighted>growth</Highlighted>.
						</BottomText>
					</BreakPoint>
				</Column>
				<Column small={12}>
					<ScrollJacket ref={scrollArrowRef} style={{ opacity: isVisible ? 1 : 0 }}>
						<BreakPoint size='large' up>
							<Icon type="sc-arrow" />
						</BreakPoint>
					</ScrollJacket>
				</Column>
			</Row>
		);
	}
);
