// Imports
// ------
import styled from 'styled-components';
import { breakup, Section, List, H2 } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)``;

export const Title = styled(H2)`
    margin-bottom: 3.6rem;
    ${breakup.medium`margin-bottom: 4.8rem;`}
    ${breakup.large` margin-bottom: 6rem; `}
`;

export const Sweater = styled(List)``;