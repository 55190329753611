// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section, Em } from '@tackl';
import { emStyles } from '@type';

// Exports
// ------
const dotSize = '2px';
const dotSpace = '60px';

export const Jacket = styled(Section)`
    transition: all 0.4s ease-in-out;
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-bottom: 3.6rem;

    background:
		linear-gradient(90deg, ${props => props.theme.bc2} calc(${dotSpace} - ${dotSize}), transparent 1%) center,
		linear-gradient(${props => props.theme.bc2} calc(${dotSpace} - ${dotSize}), transparent 1%) center,
		${props => props.theme.bc1};
    background-size: ${dotSpace} ${dotSpace};

    ${breakup.medium`
        height: 40vh;
    `}
    
    ${breakup.large`
        height: 100vh;
        max-height: 80rem;
    `}
`;


export const ContentJacket = styled.div``;

export const Text = styled(Em)`
    position: relative;
    
    &:before {
        content: "";
        display: none;
        position: absolute;
        z-index: 1;
        top: -1.2rem; left: -1.2rem; right: -1.2rem; bottom: -1.2rem;
        background-color: ${props => props.theme.bc2};
        filter: blur(2.4rem);

        ${breakup.large` display: block; `}
    }

    span {
        position: relative;
        z-index: 2;
    }
`;

export const TextLink = styled.a(props => css`
    ${emStyles}
    display: inline-block;
    position: relative;
    background: ${props.theme.bc2};
    color: ${props.theme.bc1};

    &:after {
        content: "";
        position: absolute;
        left: 0; bottom: 0; right: 0; top: 0;
        background: ${props.theme.bc2};
        mix-blend-mode: difference;
        transform: scaleY(0.05);
        transform-origin: center bottom;
        transition: transform .25s ease-in-out;
    }

    &:hover {
        &:after { transform: scaleY(1.1); }
    }

    svg {
        margin-left: 0.6rem;
        width: 0.9rem;
        height: 0.8rem;
        fill: ${props.theme.bc1};
    }
`);


export const TextLinkJacket = styled.div`
    ${props => props.isDark ? `
        ${TextLink} {
            &:after { background: ${props.theme.bc1}; }
        }
    ` : `
        ${TextLink} {
            &:after {  background: ${props.theme.bc2}; }
        }
    `}
`;

export const Bottom = styled.div`
    position: relative;
    display: inline-block;
    z-index: 3;
    margin-top: 6rem;

    ${breakup.medium`
        position: absolute;
        bottom: 0; left: 2.4rem;
        padding-bottom: 3.6rem;
    `}

    ${breakup.large`
        left: 1.8rem;
    `}
`;


export const Books = styled.div`
    position: relative;
    margin-bottom: 8.4rem;

    ${breakup.medium`
        position: absolute;
        z-index: 3;
        top: 50%; left: 50%;
        transform: translate(0%, -50%);
    `}
`;

export const Book = styled.img(props => css`
    height: auto;
    transition: transform 1s ${ props.theme.bezzy};

    ${props.isOpen && css`
        position: relative;
        z-index: 1;
        transform: translateX(50%) rotate(0deg);
        width: 40rem;

        ${breakup.medium`
            width: 46.3rem;
            transform: translateX(50%) rotate(0deg);
        `}

        ${breakup.large` width: 70.4rem; `}
        
        &.visible {
            transform: translateX(30%) rotate(-15deg);
            transition-delay: 0.5s;

            ${breakup.medium`
                transform: translateX(30%) rotate(-20deg);
            `}

            ${breakup.large`
                transform: translateX(30%) rotate(-20deg);
            `}
        }
    `}

    ${!props.isOpen && css`
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 2;
        transform: translateX(0%) rotate(0deg);
        width: 25.7rem;

        ${breakup.medium`
            width: 29.8rem;
            transform: translateX(0%) rotate(0deg);
        `}

        ${breakup.large` width: 45.5rem; `}

        &.visible {
            transform: translateX(-60%) rotate(-15deg);
            transition-delay: 0.5s;

            ${breakup.medium`
                transform: translateX(-60%) rotate(-20deg);
            `}

            ${breakup.large`
                transform: translateX(-70%) rotate(-20deg);
            `}
        }
    `}
`);