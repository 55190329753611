// Imports
// ------
import React from 'react';
import Img from 'gatsby-image';
import { lightDarkMode } from '@states/darkmode';
import { observer } from 'mobx-react';

// Styles
// ------
import {
    Jacket,
    Image,
    Caption
} from './styles';

// Component
// ------
const Accolade = ({ image, imageDark, caption }) => (
    <Jacket>
        <Image>
            {lightDarkMode.isDark ? (
                <Img fluid={imageDark.fluid} alt={imageDark.alt} />
            ) : (
                <Img fluid={image.fluid} alt={image.alt} />
            )}
        </Image>
        <Caption>{caption}</Caption>
    </Jacket>
);

export default observer(Accolade);