// Imports
// ------
import styled from 'styled-components';
import { Section, H2, Em } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)`
    .swiper-wrapper {
        transition-timing-function: linear !important;
    }
`;

export const Title = styled(H2)``;

export const Text = styled(Em)``;

export const Slider = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;
`;