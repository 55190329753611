// Imports
// ------
import React from 'react';

// Styles
// ------
import {
    Jacket
} from './styles';

// Component
// ------
const Icon = ({ type, className }) => {
    if (type === 'moon') return (
        <Jacket width="24" height="24" viewBox="0 0 24 24" className={className}>
            <path d="M21.4,13.7C20.6,13.9,19.8,14,19,14c-5,0-9-4-9-9c0-0.8,0.1-1.6,0.3-2.4c0.1-0.3,0-0.7-0.3-1 c-0.3-0.3-0.6-0.4-1-0.3C4.3,2.7,1,7.1,1,12c0,6.1,4.9,11,11,11c4.9,0,9.3-3.3,10.6-8.1c0.1-0.3,0-0.7-0.3-1 C22.1,13.7,21.7,13.6,21.4,13.7z" />
        </Jacket>
    );

    if (type === 'sun') return (
        <Jacket width="24" height="24" viewBox="0 0 24 24" className={className}>
            <path d="M12,1A11,11,0,1,0,23,12,11.012,11.012,0,0,0,12,1Z" />
        </Jacket>
    );

    if (type === 'circle') return (
        <Jacket width="11" height="10" viewBox="0 0 11 10" fill="none" className={className}>
            <circle cx="5.95703" cy="5" r="4.5" />
        </Jacket>
    );

    if (type === 'reset') return (
        <Jacket width="24" height="24" viewBox="0 0 24 24" className={className}>
            <path d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z" />
        </Jacket>
    );

    if (type === 'close') return (
        <Jacket width="24" height="24" viewBox="0 0 24 24" className={className}>
            <path d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"></path>
        </Jacket>
    );

    if (type === 'sc-arrow') return (
        <Jacket width="58" height="78" viewBox="0 0 58 78" className={className}>
            <path d="M32.2285 0.980469H32.7285V0.480469H32.2285V0.980469ZM25.4902 0.980469V0.480469H24.9902V0.980469H25.4902ZM25.4902 65.8242V66.3242H25.9902V65.8242H25.4902ZM22.1211 65.8242L21.627 65.9007L21.6925 66.3242H22.1211V65.8242ZM16.4082 55.1309L16.7641 54.7797L16.7625 54.7781L16.4082 55.1309ZM5.51953 44.1934L5.87387 43.8406L5.51849 43.4836L5.16415 43.8416L5.51953 44.1934ZM0.832031 48.9297L0.476651 48.578L0.125218 48.9331L0.480028 49.2848L0.832031 48.9297ZM28.8594 76.7129L28.5074 77.068L28.8594 77.4169L29.2114 77.068L28.8594 76.7129ZM56.8867 48.9297L57.2387 49.2848L57.5935 48.9331L57.2421 48.578L56.8867 48.9297ZM52.1992 44.1934L52.5546 43.8416L52.2003 43.4836L51.8449 43.8406L52.1992 44.1934ZM40.9687 55.4727L40.6144 55.1198L40.6077 55.1268L40.9687 55.4727ZM35.5977 65.8242V66.3242H36.0254L36.0916 65.9016L35.5977 65.8242ZM32.2285 65.8242H31.7285V66.3242H32.2285V65.8242ZM32.2285 0.480469L25.4902 0.480469V1.48047L32.2285 1.48047V0.480469ZM24.9902 0.980469L24.9902 65.8242H25.9902L25.9902 0.980469H24.9902ZM25.4902 65.3242H22.1211V66.3242H25.4902V65.3242ZM22.6152 65.7477C21.9626 61.5306 20.4912 58.5564 16.7641 54.7797L16.0523 55.4821C19.6494 59.1271 21.0101 61.9147 21.627 65.9007L22.6152 65.7477ZM16.7625 54.7781L5.87387 43.8406L5.16519 44.5461L16.0539 55.4836L16.7625 54.7781ZM5.16415 43.8416L0.476651 48.578L1.18741 49.2814L5.87491 44.5451L5.16415 43.8416ZM0.480028 49.2848L28.5074 77.068L29.2114 76.3578L1.18403 48.5746L0.480028 49.2848ZM29.2114 77.068L57.2387 49.2848L56.5347 48.5746L28.5074 76.3578L29.2114 77.068ZM57.2421 48.578L52.5546 43.8416L51.8438 44.5451L56.5313 49.2814L57.2421 48.578ZM51.8449 43.8406L40.6144 55.1199L41.3231 55.8254L52.5535 44.5461L51.8449 43.8406ZM40.6077 55.1268C37.2245 58.6592 35.7559 61.5825 35.1037 65.7468L36.0916 65.9016C36.7089 61.9605 38.0724 59.2197 41.3298 55.8185L40.6077 55.1268ZM35.5977 65.3242H32.2285V66.3242H35.5977V65.3242ZM32.7285 65.8242L32.7285 0.980469H31.7285L31.7285 65.8242H32.7285Z" />
        </Jacket>
    );

    if (type === `btn-arrow`) return (
        <Jacket width="9" height="8" viewBox="0 0 9 8" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.3107 7.85722L6.92471 2.22922V5.99522H8.1147V0.199219H2.31871V1.38922H6.09871L0.470703 7.01722L1.3107 7.85722Z" />
        </Jacket>
    );

    console.error(`You've not added the correct 'type' prop to your <Icon /> component`);
    console.warn(`Remember to add "type='YOUR_ICON_NAME'" to your <Icon /> props or else your icon wont display`);
    return null;
}


export default React.memo(Icon);