// Imports
// ------
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Row, Column } from '@waffl';

// Styles
// ------
import {
    Jacket,
    Title,
    Content
} from './styles';

// Component
// ------
const Focus = ({ title, content }) => (
    <Jacket pad>
        <Row isExpanded isEqual>
            <Column small={12} medium={4} large={5}>
                <Title>{title}</Title>
            </Column>
            <Column small={12} medium={8} large={6} pushOnLarge={1}>
                <Content>{ReactHtmlParser(content)}</Content>
            </Column>
        </Row>
    </Jacket>
)

export default React.memo(Focus);