// Imports
// ------
import styled, { keyframes } from 'styled-components';
import { breakup, Section, Em } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)`
    padding: 6rem 0;
    position: relative;
    overflow: hidden;

    ${breakup.large` padding: 8rem 0; `}
`;

export const MarqueeJacket = styled.div`
    position: relative;
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
`;

export const QuoteText = styled.span`
    position: relative;
    display: block;
    vertical-align: middle;
    padding: 1rem 1.2rem;
    margin-right: 6rem;

    font-family: ${props => props.theme.serif};
    font-weight: ${props => props.theme.light};
    font-size: 4rem;

    ${breakup.medium`
        display: inline-block;
        font-size: 5rem;
        padding: 1rem 0;
        padding-left: 12rem;
    `}

    ${breakup.large` font-size: 6.4rem; `}
`;

export const QuoteAuthor = styled(Em)`
    position: absolute;
    bottom: 2.4rem;
    right: 1.2rem;
`;

export const QuoteImage = styled.div`
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding: 1rem 0;
    margin-left: 1.2rem;
    margin-bottom: 1.2rem;

    ${breakup.medium`
        display: inline-block;
        padding: 1rem 0;
        margin: 0;
    `}
`;