// Imports
// ------
import React from 'react';
import { Row, Column } from '@waffl';
import { formQuestions } from '@states/formQuestions';
import { observer } from 'mobx-react';

// Styles
// ------
import {
    Jacket,
    Title,
    CategoriesJacket,
    Category,
    Name,
    ClientList,
} from './styles';

// Component
// ------
const Clientele = ({ categories }) => (
    <Jacket padBottom borderBottom marBottom>
        <Row isExpanded>
            <Column small={12} medium={6} large={4}>
                <Title>Clientele</Title>
            </Column>
            <Column small={12} medium={6} pushOnLarge={2}>
                <CategoriesJacket>
                    <Row isExpanded>
                        {/* Array that spits out only selected value */}
                        {categories.map(({ name, clientList }, i ) => {
                            if(name !== "General Interest") {
                                if(name === formQuestions.q1Selection) return (
                                    <Column small={6} key={`${name}${i}`}>
                                        <Category>
                                            <Name>{name}</Name>
                                            <ClientList dangerouslySetInnerHTML={{ __html: clientList}} />
                                        </Category>
                                    </Column>
                                );
                            }
                        })}

                        {/* Array that spits out everything but selected value */}
                        {categories.map(({ name, clientList }, i ) => {
                            if(name !== "General Interest") {
                                if(name !== formQuestions.q1Selection) return (
                                    <Column small={6} key={`${name}${i}`}>
                                        <Category>
                                            <Name>{name}</Name>
                                            <ClientList dangerouslySetInnerHTML={{ __html: clientList}} />
                                        </Category>
                                    </Column>
                                );
                            }
                        })}
                    </Row>
                </CategoriesJacket>
            </Column>
        </Row>
    </Jacket>
);

export default observer(Clientele);