import { ShaderMaterial, UniformsLib, Vector2, Vector3 } from './three';
import curl from './curl';
import iriTexture from './iridescenceTexture';
import ThinFilmFresnelMap from './iridescenceTexture';
// https://github.com/DerSchmale/threejs-thin-film-iridescence/blob/master/js/ThinFilmFresnelMap.js
let vertexShader = `
${curl}
    varying vec3 worldNormal;
    varying vec3 viewDirection;

    varying vec3 WorldPos;
	uniform float uTime;
	uniform float uStartTime;
    uniform float uDistortionAmplitude;
    uniform float uDistortionFrequency;
    uniform float uDistortionSpeed;
    uniform float uPixelationEase;
	varying vec2 vUv;
	uniform vec2 uMouse;
	uniform vec3 uMousePoint;
	uniform float uMouseAmplitude;
    float nsin(float t){
      return sin(t) *0.5 + 0.5;
    }
    float ncos(float t){
      return cos(t) *0.5 + 0.5;
    }
    
    vec3 distort(vec3 p){
		float dist = smoothstep(0.4,1.,dot(normalize(uMousePoint), normalize(p) ));
		vec3 direction = normalize(p);
		float fromCamera = dot(direction, normalize(uMousePoint));
		float freq = 1.5; 
		float t = uTime * 5. - uStartTime * 5.;
		float tt = (uTime - uStartTime) * 100.;
		float start = 1.;
		float n = snoise(direction * uDistortionFrequency * (uMouseAmplitude + 0.1) * 1.5 * 0.99 + vec3((uTime))  * 4. * uDistortionSpeed)  * (dist) * 2. * (uMouseAmplitude ) * 1.
		+ snoise(direction * uDistortionFrequency/2. * 3. * 0.99 + vec3(0.,0.,uTime) * uDistortionSpeed * 2. + vec3(uMouse, 0.) *0.2  )  * 0.7 
		+ cos(fromCamera * 3.14 * freq + 3.14 +tt )* 6. * smoothstep(start * 3.14 * freq , start * 3.14 * freq + 3.14 * freq , fromCamera * 3.14 * freq  +tt)
		* smoothstep( start * 3.14 * freq + 3.14 * freq ,start * 3.14 * freq , fromCamera * 3.14 * freq +tt  );
	
		return direction * (n * uDistortionAmplitude + length(p));
    }
    vec3 orthogonal(vec3 v) {
      return normalize(abs(v.x) > abs(v.z) ? vec3(-v.y, v.x, 0.0)
      : vec3(0.0, -v.z, v.y));
    }
    varying float amp;
    vec3 distortNormal(vec3 position, vec3 distortedPosition, vec3 normal){
      
      vec3 tangent1 = orthogonal(normal);
      vec3 tangent2 = normalize(cross(normal, tangent1));
      vec3 nearby1 = position + tangent1 * 0.1;
      vec3 nearby2 = position + tangent2 * 0.1;
      vec3 distorted1 = distort(nearby1);
      vec3 distorted2 = distort(nearby2);
      return normalize(cross(distorted1 - distortedPosition, distorted2 - distortedPosition));
    }

varying float rgbAmplitude;
varying float vDistortionAmp;
varying mat4 mMatrix;
    void main() {
        vec3 transformed = position;

        // Distortion      

        transformed = distort(position);
        vec3 distortedNormal = distortNormal(position, transformed, normal);
        vec4 worldPosition = modelMatrix * vec4(transformed, 1.);
        // Refraction
        viewDirection = normalize(worldPosition.xyz - cameraPosition);

        worldNormal = normalize( modelViewMatrix * vec4(distortedNormal, 0.)).xyz;

		gl_Position =  projectionMatrix * modelViewMatrix * vec4(transformed, 1.);
		float dist = smoothstep(0.8,1.,dot(normalize(uMousePoint), normalize(position) ));
		
		vDistortionAmp = clamp(1.-abs(distance(position, transformed)),0., 1.);
		vUv = uv;
	}
`;

let fragmentShader = `#define PI 3.1415926538
${curl}
uniform sampler2D envMap;
uniform vec2 resolution;
varying vec3 worldNormal;
varying vec3 viewDirection;
uniform float uRefractAmp;
varying float vDistortionAmp;
varying vec2 vUv;
 
vec2 hash22(vec2 p)
{
  vec3 p3 = fract(vec3(p.xyx) * vec3(.1031, .1030, .0973));
    p3 += dot(p3, p3.yzx+33.33);
    return fract((p3.xx+p3.yz)*p3.zy);

}

vec3 orthogonal(vec3 v) {
  return normalize(abs(v.x) > abs(v.z) ? vec3(-v.y, v.x, 0.0)
  : vec3(0.0, -v.z, v.y));
}
uniform float uPixelationAmplitude;
uniform vec3 uAmbientColor;
uniform float uRefractIOR;
uniform vec2 uMouse;
uniform sampler2D uIriMap;
uniform float uIriBoost;
uniform bool isDark;
uniform float uDarkProgress;
float nsin(float val){
	return sin(val)*0.5 + 0.5; 
}
  void main() {
	  // Normal + noise detail.
    vec3 N = normalize(worldNormal + snoise(worldNormal * 5.) *vec3(0.2, 0.2, 0.) );
	vec2 uv = gl_FragCoord.xy / resolution;
	// Pixelation
    uv += (hash22(uv * 100.)*2.-1.) *.001* vDistortionAmp * uPixelationAmplitude;
	float rgbIOR = 1.-abs(dot(N, normalize(cameraPosition)));

    // Refaction + RGB
	vec3 refracted = refract(viewDirection,N, uRefractIOR - 0.05 * rgbIOR ) * uRefractAmp;
    vec3 refractedG = refract(viewDirection,N, uRefractIOR ) * uRefractAmp;
    vec3 refractedB = refract(viewDirection,N, uRefractIOR  + 0.05 * rgbIOR  ) * uRefractAmp;
	
	vec4 tex = vec4(0.);
	
	vec2 uvR = uv;
    uvR += -refracted.xy;
	tex.rgb += texture2D(envMap, uvR).r * (vec3(155, 50, 50) / 255.);
	
	vec2 uvG = uv;
    uvG += -refractedG.xy;
    tex.rgb += texture2D(envMap, uvG).g * (vec3(50, 155, 50) / 255.);

	vec2 uvB = uv;
    uvB += -refractedB.xy;
    tex.rgb += texture2D(envMap, uvB).b * (vec3(50, 50, 155) / 255.);

    vec3 albedo = vec3(tex.rgb);
    
		float NdotV = max(-dot(cameraPosition, N), 0.);
	  vec3 iri = texture2D(uIriMap, vec2(NdotV*.99, 0.)).rgb; 
	  iri *= iri;
      
	  float NdotCam =       dot(N, normalize(cameraPosition));


		// darkmode
		vec3 prettyNormal = vec3(
			smoothstep ( 0.9, 1. , (nsin(worldNormal.r * 3.))) ,
			smoothstep( 0.0, 0.7, nsin(worldNormal.b * 10.+ worldNormal.g * 3. )),
			smoothstep( 0.0, 0.7, nsin(worldNormal.b * 10.  + 0.1 )) + 0.5  );

		float colorShading = smoothstep(0., 0.8, NdotCam);
		vec3 shadedColor = albedo * colorShading;
 // First is non dark, second is dark.
		shadedColor = mix( 1.-((1.- albedo) * colorShading), shadedColor, uDarkProgress);
		// if(!isDark){
		// 	shadedColor = 1.-((1.- albedo) * colorShading);
		// }
		prettyNormal = prettyNormal * vec3(iri) * 2. * uIriBoost;
		gl_FragColor.rgb = vec3(mix(prettyNormal , shadedColor, smoothstep(0.3, 0.6, NdotCam) ) );
		// gl_FragColor.rgb = prettyNormal;

  }
`;

export default class RefractionMaterial extends ShaderMaterial {
	constructor({ envMap, resolution, isDarkMode = true }) {
		super({
			fragmentShader,
			vertexShader,
			// depthTest: false,
			// depthWrite: false,
		});
		this.uniforms = {
			...UniformsLib['lights'],
			envMap: { value: envMap },
			resolution: { value: resolution },
			uTime: { value: 0 },
			uStartTime: { value: 0 },
			uRefractAmp: { value: 0.3 },
			uPixelationAmplitude: { value: 1 },
			uMetalic: { value: 0.2 },
			uRough: { value: 0.8 },
			uDarkProgress: { value: isDarkMode ? 1 : 0 },
			uLightColor: { value: [1, 1, 1] },
			uAmbientColor: { value: [0.15, 0.15, 0.15] },
			uLightPos: { value: [0, 0.5] },
			uLightZ: { value: 30 },
			uDistortionAmplitude: { value: 1 },
			uDistortionSpeed: { value: 1 },
			uDistortionFrequency: { value: 1 },
			uPixelationEase: { value: 2 },
			uRefractIOR: { value: 0.9 },
			uMouse: { value: new Vector2(resolution[0], 0) },
			uMousePoint: { value: new Vector3(0, 0, 1) },
			uMouseAmplitude: { value: 0 },
			uIriBoost: { value: 3 },
			uIsDarkMode: { value: isDarkMode },
			uMouse: { value: new Vector2(0, 0) },
			uIriMap: { value: new ThinFilmFresnelMap(1000, 1.2, 3.2) },
			uRGBcolorR: { value: new Vector3(155, 50, 50) },
			uRGBcolorG: { value: new Vector3(50, 155, 50) },
			uRGBcolorB: { value: new Vector3(50, 50, 155) },
		};
	}
}
