// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Em, P, List, ListItem } from '@tackl';
import { emStyles } from '@type';

// Exports
// ------
export const Jacket = styled.div`
    position: relative;
    padding: 2.4rem;
    border: 1px solid ${props => props.theme.bc1};
    box-shadow: 0px 4px 20px rgba(000,000,000, 0.2);
`;

export const Content = styled.div`

`;

export const Sub = styled(Em)`
    opacity: 0.35;
`;

export const Main = styled(P)`
    margin-bottom: 3.6rem;
`;

export const OptionsList = styled(List)`
    transition: all 0.4s ease;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 2.4rem;

    ${props => props.isHidden && css`
        opacity: 0;
        pointer-events: none;
    `}

    li {
        margin-top: 2.4rem;
        flex: ${props => props.split ? `0 50%` : `0 100%`};
    }
`;

export const Option = styled(ListItem)`
    ${emStyles}
    position: relative;
    background-color: ${props => props.theme.bc2};
    transition: all .3s ease-in-out;

    span {
        ${emStyles}
        display: inline;
        position: relative;
        background: none;
        padding: 0;
        margin: 0;
        transition: all .3s ease-in-out;
        color: ${props => props.theme.bc1};
    }
`;

export const OptionButton = styled.button`
    display: inline;
    position: relative;
    background: none;
    padding: 0;
    margin: 0;
    transition: all .3s ease-in-out;
    color: ${props => props.theme.bc1};

    &:hover {
        cursor: pointer;
        &:after { transform: scaleY(1.1); }
    }

    &:after {
        content: '';
        position: absolute;
        z-index: 2;
        top: 0; left: 0; right: 0; bottom: 0;
        background: ${props => props.theme.white};
        mix-blend-mode: difference;
        transform: scaleY(0.05);
        transform-origin: center bottom;
        transition: all .3s ease-in-out;
    }
`;


export const Confirmation = styled.div`
    transition: all .3s ease-in-out;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    width: 100%;
    height: 100%;
    padding: 2.4rem;
    background: ${props => props.theme.bc2};
`;

export const ConfirmationLeft = styled.div`
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: space-between;
    flex: 1;
`;

const offset = `2.4rem`;

export const Reset = styled.button`
    position: absolute;
    left: 0; bottom: 0;
    padding: ${offset};
    transform: translate(-${offset}, ${offset});
    background: transparent;

    &:hover {
        svg { opacity: 0.5; }
    }

    svg {
        width: 1.6rem;
        height: 1.6rem;
        fill: ${props => props.theme.bc1};
        transition: all .3s ease;
    }
`;


export const ConfirmationRight = styled.div`
    width: 100%;
    max-width: 28.8rem;
`;

export const Label = styled(Em)`
    display: flex;
    flex: 1;
    ${props => props.right && `text-align: right;`}
`;


export const ButtonList = styled.div`
    position: relative;
    margin-top: 3.6rem;

    ${breakup.large` margin-top: 6rem; `}

    button {
        margin-bottom: 1.2rem;
        &:last-child { margin-bottom: 0; }
    }
`;