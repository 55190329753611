// Imports
// ------
import styled from 'styled-components';
import { breakup, Section, H2, ContentBlock } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)``;

export const Title = styled(H2)`
    margin-bottom: 3.6rem;
    
    ${breakup.medium`
        position: sticky;
        top: 10rem; left: 0;
    `}

    ${breakup.large` top: 15rem; `}
`;

export const Content = styled(ContentBlock)`
    margin-bottom: 4rem;
`;