import React from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

export const BreakPoint = ({ size, up, children }) => {
	const breakpoints = useBreakpoint();

	let direction = true;
	if (up != null) {
		direction = false;
	}

	if (breakpoints[size] == direction) {
		return children;
	}
	return null;
};
