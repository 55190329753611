// Imports
// ------
import styled from 'styled-components';
import { breakup, Section, P } from '@tackl';
import { emStyles } from '@type';

// Exports
// ------
export const Jacket = styled(Section)``;

export const Title = styled(P)`
    text-align: center;
`;

export const Content = styled.div`
    margin-top: 1.2rem;
    text-align: center;

    p {
        ${emStyles}
        margin-bottom: 2.4rem;

        &:last-child { margin: 0; }
    }
`;

export const LogoJacket = styled.div`
    position: relative;
    display: block;
    text-align: center;
    padding: 12rem 0;

    ${breakup.medium`padding: 0;`}
`;

export const Cta = styled(P)`
    margin-top: 12rem;
    text-transform: uppercase;
    text-align: center;

    a {
        position: relative;
        display: inline-block;
        background: ${props => props.theme.bc2};
        transition: background 0.1s linear;

        &:hover {
            cursor: pointer;
            &:after { transform: scaleY(1.05); }
        }
    
        &:after {
            content: '';
            position: absolute;
            z-index: 2;
            top: 0; left: 0; right: 0; bottom: 0;
            background: ${props => props.theme.white};
            mix-blend-mode: difference;
            transform: scaleY(0.05);
            transform-origin: center bottom;
            transition: all .25s ease;
        }
    }
`;