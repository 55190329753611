// Imports
// ------
import React from 'react';
import LogoSquare from '@images/logo-square.svg';
import LogoCircle from '@images/logo-circle.svg';
import { Row, Column } from '@waffl';

// Styles
// ------
import {
    Jacket,
    Title,
    Content,
    LogoJacket,
    Cta
} from './styles';

// Component
// ------
const Company = ({ title, content }) => (
    <Jacket pad>
        <Row isExpanded isCenter>
            <Column small={12} medium={3} large={2} pushOnLarge={1}>
                <LogoJacket>
                    <img src={LogoSquare} alt="Levin Riegner" />
                </LogoJacket>
            </Column>
            <Column small={12} medium={6} pushOnLarge={1}>
                <Title large>{title}</Title>
                <Content dangerouslySetInnerHTML={{ __html: content }} />
            </Column>
            <Column small={12} medium={3} large={2} pushOnLarge={1}>
                <LogoJacket>
                    <img src={LogoCircle} alt="Levin Riegner" />
                </LogoJacket>
            </Column>
        </Row>
    </Jacket>
);

export default Company;