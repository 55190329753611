// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, P } from '@tackl';

// Exports
// ------
export const Jacket = styled.div(props => css`
    position: relative;
    display: block;
    margin: 0 0 3.6rem;
    overflow: hidden;

    ${breakup.large`
        margin: 0 calc(3.6rem / 2);
        padding: 2.4rem 0;
        padding-left: calc(8rem + 1px);
        border-top: 1px solid ${props.theme.bc1};
        ${props.isLast && css` border-bottom: 1px solid ${props.theme.bc1}; `}

        &:before {
            content: '';
            position: absolute;
            top: 0; left: 8rem;
            height: 100%;
            border-left: 1px solid ${props.theme.bc1};
        }
    `}
`);


export const TitleJacket = styled.div`
    ${breakup.large`
        position: absolute;
        top: 0; left: 0; bottom: 0;
        width: 8rem;
    `}
`;

export const Title = styled(P)(props => css`
    position: relative;
    display: block;

    padding: .5rem 0;
    border-top: 1px solid ${props.theme.bc1};
    border-bottom: 1px solid ${props.theme.bc1};

    ${breakup.large`
        border: 0;
        margin: 0;
        padding-top: 2.4rem;

        position: absolute;
        z-index: 12;
        top: 50%; left: 50%;
        transform-origin: center center;
        transform: translate(-50%, -50%) rotate(90deg);
    `}
`);

export const Slider = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;
`;