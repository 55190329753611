// Imports
// ------
import React, { useEffect, useRef } from 'react';
import Slide from './Slide';

// Styles
// ------
import {
    Jacket,
    TitleJacket,
    Title,
    Slider
} from './styles';

// Component
// ------
const NoeSection = ({ isLast, title, content, speed }) => {
    const noeRef = useRef();

    let noeRefSpeed = speed;

    let flickity = null;
    let isPaused = false;

    useEffect(() => {
        const Flickity = require("flickity");

        const slider = noeRef.current;

        const update = () => {
            if (isPaused) return;
            if (flickity.slides) {
                flickity.x = (flickity.x - noeRefSpeed) % flickity.slideableWidth;
                flickity.selectedIndex = flickity.dragEndRestingSelect();
                flickity.updateSelectedSlide();
                flickity.settle(flickity.x);
            }
            typeof window !== 'undefined' && window.requestAnimationFrame(update);
        };
    
        const pause = () => {
            isPaused = true;
        };
        
        const play = () => {
            if (isPaused) {
                isPaused = false;
                typeof window !== 'undefined' && window.requestAnimationFrame(update);
            }
        };
    
        flickity = new Flickity(slider, {
            autoPlay: false,
            prevNextButtons: false,
            pageDots: false,
            draggable: true,
            wrapAround: true,
            selectedAttraction: 0.015,
            friction: 0.25,
        });
        
        flickity.x = 0;
    
        slider.addEventListener('mouseenter', pause, false);
        slider.addEventListener('focusin', pause, false);
        slider.addEventListener('mouseleave', play, false);
        slider.addEventListener('focusout', play, false);
    
        flickity.on('dragStart', () => {
            isPaused = true;
        });
    
        update();
    }, []);

    return (
        <Jacket isLast={isLast}>
            <TitleJacket>
                <Title large>{title}</Title>
            </TitleJacket>
            
            <Slider ref={noeRef}>
                {content.map(({ title, image, description }, i) => (
                    <Slide
                        key={`noe-` + i}
                        image={image.fluid}
                        title={title}
                        desc={description}
                    />
                ))}
            </Slider>
        </Jacket>
    );
}

export default NoeSection;