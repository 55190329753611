// Imports
// ------
import styled from 'styled-components';
import { Section, H1 } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)`
    overflow: hidden;
`;

export const Title = styled(H1)`
    margin-bottom: 3.6rem;
    color: ${props => props.theme.bc1};
`;