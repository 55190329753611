// Imports
// ------
import Webgl from './webgl';
import React from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'react-scroll-trigger';
import { observe } from 'mobx';
import { lightDarkMode } from '@states/darkmode';
import { PostIntro } from './PostIntro';
import { PreIntro } from './PreIntro';

// Styles
// ------
import { Jacket, Canvas, MainRow } from './styles';

// Component
// ------
class Intro extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			webgl: null,
			isIntroDone: false,
			queueIntro: false,
			initialHeight: 0,
		};
		this.canvasRef = React.createRef();
		// Texture Components
		this.luxuryRef = React.createRef();
		this.techRef = React.createRef();
		this.designRef = React.createRef();
		// regular html eles
		this.textLeftRef = React.createRef();
		this.textRightRef = React.createRef();
		this.scrollArrowRef = React.createRef();
	}
	componentDidUpdate(prevProps, prevState) {
		if (
			this.state.queueIntro &&
			this.textLeftRef.current != null &&
			this.textRightRef.current != null &&
			this.scrollArrowRef.current != null
		) {
			this.playIntro();
		}
		if (this.state.webgl) {
			this.state.webgl.updateDarkMode(lightDarkMode.isDark);
		}
	}

	playIntro() {
		if (this.state.webgl) {
			this.setState({
				queueIntro: false,
			});
			setTimeout(() => {
				this.textLeftRef.current.style.opacity = 0;
				this.textRightRef.current.style.opacity = 0;
				this.scrollArrowRef.current.style.opacity = 0;
				this.state.webgl.intro({
					lux: this.luxuryRef.current.masterObject,
					tech: this.techRef.current.masterObject,
					design: this.designRef.current.masterObject,
					onComplete: () => {
						let t1 = gsap.timeline({
							onComplete: () => {
								this.setState({ isIntroDone: true });
							},
						});
						t1.to(
							this.textLeftRef.current,
							{
								opacity: 1,
								duration: 1,
								ease: 'power2.inOut',
							},
							0
						);
						t1.to(
							this.textRightRef.current,
							{
								opacity: 1,
								duration: 1,
								ease: 'power2.inOut',
							},
							0
						);
						t1.to(
							this.scrollArrowRef.current,
							{
								opacity: 1,
								duration: 1.2,
								ease: 'power2.inOut',
							},
							0
						);
					},
				});
			}, 1000);
		}
	}

	componentDidMount() {
		this.setState({
			initialHeight: window.innerHeight,
		});
		let webgl = new Webgl(this.canvasRef.current, {
			isDarkMode: lightDarkMode.isDark,
			disableTweak: true,
		});
		webgl.onResizeCB.push(this.resize);

		webgl.init();

		this.setState({ webgl: webgl, queueIntro: true });

		this.observerDisposer = observe(
			lightDarkMode,
			'isDark',
			this.onThemeChange
		);
	}
	resize() {
		// if (this.state.webgl == null) return;
		// let viewAt0 = this.state.
	}
	componentWillUnmount() {
		if (this.observerDisposer) this.observerDisposer();
	}

	onThemeChange = (change) => {
		if (this.state.webgl) this.state.webgl.updateDarkMode(lightDarkMode.isDark);
	};


	onEnter = () => {
		if (this.state.webgl) {
			this.state.webgl.disableRender = false;
		}
	};

	onExit = () => {
		if (this.state.webgl) {
			this.state.webgl.disableRender = true;
		}
	};
	render() {
		const { initialHeight } = this.state;
		let maxHeight = initialHeight === 0 ? null : initialHeight;
		return (
			<ScrollTrigger onEnter={this.onEnter} onExit={this.onExit}>
				<Jacket fullscreen>
					<Canvas
						ref={this.canvasRef}
						style={{ maxHeight: `${maxHeight}px` }}
					/>
					<MainRow isExpanded isBottom maxHeight={maxHeight}>
						<PreIntro
							ref={{
								luxury: this.luxuryRef,
								design: this.designRef,
								tech: this.techRef,
								text0: this.textLeftRef,
								text1: this.textRightRef,
							}}
							maxHeight={maxHeight}
							webgl={this.state.webgl}
							isVisible={this.state.isIntroDone}
						/>
						<PostIntro
							ref={{
								scrollArrowRef: this.scrollArrowRef,
								p0Ref: this.textLeftRef,
								p1Ref: this.textRightRef,
							}}
							isVisible={this.state.isIntroDone}
						/>
					</MainRow>
				</Jacket>
			</ScrollTrigger>
		);
	}
}

export default Intro;
