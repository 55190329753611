// Imports
// ------
import styled from 'styled-components';
import { breakup, Section, H2, P } from '@tackl';
import { pStyles } from '@type';

// Exports
// ------
export const Jacket = styled(Section)``;

export const Title = styled(H2)`
    margin-bottom: 3.6rem;
`;

export const CategoriesJacket = styled.div`
    margin-top: 3.6rem;

    ${breakup.large` margin-top: 0; `}
`;

export const Category = styled.div`
    position: relative;
    display: block;
    margin-bottom: 3.6rem;

    ${breakup.large` margin-bottom: 8rem; `}
`;

export const Name = styled(P)`
    display: block;
    margin-bottom: 2.4rem;

    opacity: 0.5;
    text-transform: uppercase;
`;

export const ClientList = styled.div`
    ul {
        list-style: none;

        li {
            ${pStyles}
        }
    }
`;