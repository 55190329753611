import {
	WebGLRenderTarget,
	WebGLRenderer,
	Scene,
	Mesh,
	Vector2,
	PerspectiveCamera,
	Color,
	Clock,
	Raycaster,
	Vector3,
	PlaneBufferGeometry,
	MeshBasicMaterial,
	SphereBufferGeometry,
} from './three';
// import * as THREE from 'three';
import PBR from './PBR';
import blurPostProcess from './blurPostProcess';
import { AntialiasPostProcess } from './blurPostProcess';
import lerp from 'lerpy';
import gsap from 'gsap';
// import { initTweak } from './configurator';
import debounce from 'lodash.debounce';

let springuInline = (object, key, target, options) => {
	let current = object[key];
	let currentVelocity = object[key + 'Velocity'];
	if (currentVelocity == null) {
		currentVelocity = 0;
		object[key + 'Velocity'] = 0;
	}
	//
	// Don't use my lerp bc I need to floor it after
	let acceleration = (target - current) * options.spring;
	let velocity =
		(currentVelocity * options.damping + acceleration) * options.friction;

	//   velocities[key] = velocity
	if (Math.abs(velocity) < options.rest) {
		object[key + 'Velocity'] = 0;
		return target - current;
	}
	object[key + 'Velocity'] = velocity;

	return velocity;
};

// Teeny-tiny spring utility
let springu = (obj, options) => {
	let optionKeys = Object.keys(options);
	let keys = [];
	let velocities = {};
	let targets = {};

	for (let i = 0; i < optionKeys.length; i++) {
		let key = optionKeys[i];
		if (
			key === 'spring' ||
			key === 'friction' ||
			key === 'rest' ||
			key == 'damping'
		)
			continue;
		velocities[key] = 0;
		targets[key] = options[key];
		keys.push(key);
	}

	return {
		velocities,
		targets,
		object: obj,
		update: (options) => {
			let keys = Object.keys(options);
			for (let i = 0; i < keys.length; i++) {
				let key = keys[i];
				if (targets[key] == null) return;
				targets[key] = options[key];
			}
		},
		tick: () => {
			for (let i = 0; i < keys.length; i++) {
				let key = keys[i];
				let currentVal = obj[key];
				// Don't use my lerp bc I need to floor it after
				let acc = (targets[key] - currentVal) * options.spring;
				let velocity =
					(velocities[key] * options.damping + acc) * options.friction;

				obj[key] = currentVal + velocity;
				velocities[key] = velocity;

				if (Math.abs(velocity) < options.rest) {
					obj[key] = targets[key];
					velocities[key] = 0;
				}
			}
		},
	};
};

export default class App {
	constructor(canvas, options) {
		this.options = options;
		this.renderer = new WebGLRenderer({
			antialias: false,
			canvas,
			alpha: false,
			// No need for depth or stencil. Using render order for everything.
			depth: false,
			stencil: false,
		});

		// Always use canvas size. It's more reliable on mobile than window
		let useCanvas = canvas && canvas.offsetHeight > 0;
		this.vp = {
			width: window.innerWidth,
			height: window.innerHeight,
			dpr: Math.min(devicePixelRatio, 2),
		};
		this.disableRender = false;

		this.renderer.setSize(this.vp.width, this.vp.height, false);
		this.renderer.setPixelRatio(this.vp.dpr);
		// container.append(this.renderer.domElement);

		this.renderer.setClearColor(new Color(0x000000), 0);

		this.camera = new PerspectiveCamera(
			45,
			this.vp.width / this.vp.height,
			0.1,
			10000
		);
		// this.controls = new OrbitControls(this.camera, this.renderer.domElement);

		this.textCamera = new PerspectiveCamera(
			45,
			this.vp.width / this.vp.height,
			0.1,
			10000
		);
		this.renderer.autoClear = false;

		// this.textCamera.layers.set(1);

		this.camera.position.z = 50;
		this.textCamera.position.z = 50;
		this.scene = new Scene();
		this.contentScene = new Scene();
		this.contentScene.background = new Color(
			this.options.isDarkMode ? 0xffffff : 0xffffff
		);
		// this.scene.background = new THREE.Color(0xffffff);
		this.positions = {
			home: { x: 0, y: 0 },
			target: { x: 0, y: 0 },
		};

		this.envFBO = new WebGLRenderTarget(
			this.vp.width * this.vp.dpr,
			this.vp.height * this.vp.dpr,
			{
				depthBuffer: false,
				stencilBuffer: false,
			}
		);
		this.blurPostProcess = new blurPostProcess(
			this.renderer,
			{
				width: (this.vp.width * this.vp.dpr) / 2,
				height: (this.vp.height * this.vp.dpr) / 2,
			},
			null,
			{}
		);

		this.aaPostProcess = new AntialiasPostProcess(
			this.renderer,
			{
				width: this.vp.width * this.vp.dpr,
				height: this.vp.height * this.vp.dpr,
			},
			{ depthBuffer: true, stencilBuffer: false }
		);
		this.clock = new Clock();

		this.raycaster = new Raycaster();
		this.mouse = new Vector2();
		this.mouseAmplitude = 0;
		this.onResizeCB = [];

		this.viewAt0 = this.getViewSizeAtDepth();
		this.isIntroDone = false;
		this.mouseNormal = new Vector3(0, 0, 1);
	}
	addEvents() {
		window.addEventListener('mousemove', this.onMouseMove);
		window.addEventListener('touchmove', this.onMouseMove);
		window.addEventListener('click', this.onMouseDown);
		window.addEventListener('touchstart', this.onMouseMove);
		window.addEventListener('resize', debounce(this.resize, 20));
	}
	getPos(e) {
		const ev = e.changedTouches ? e.changedTouches[0] : e;
		const target = e.target;

		return {
			clientX: ev.clientX,
			clientY: ev.clientY,
			pageX: ev.pageX,
			pageY: ev.pageY,
			target,
			ev,
		};
	}
	onMouseMove = (ev) => {
		let pos = this.getPos(ev);
		if (!this.isIntroDone) return;
		this.mouse.x = (pos.pageX / window.innerWidth) * 2 - 1;
		this.mouse.y = -(pos.pageY / window.innerHeight) * 2 + 1;
		// this.mesh.material.uniforms.uMouse.value.set(this.mouse.x, this.mouse.y);

		this.raycaster.setFromCamera(this.mouse, this.camera);

		// calculate objects intersecting the picking ray
		var intersects = this.raycaster.intersectObjects(this.intersect);

		if (intersects.length === 0) {
			this.mouseAmplitude = 0;
			document.body.style.cursor = 'default';
			return;
		}
		document.body.style.cursor = 'pointer';
		this.mouseAmplitude = 1;
		this.mouseNormal = intersects[0].point;
		// this.mesh.material.uniforms.uMousePoint.value.copy(intersects[0].point);
	};
	onMouseDown = (ev) => {
		if (!this.isIntroDone) return;
		let pos = this.getPos(ev);

		this.mouse.x = (pos.pageX / window.innerWidth) * 2 - 1;
		this.mouse.y = -(pos.pageY / window.innerHeight) * 2 + 1;
		this.raycaster.setFromCamera(this.mouse, this.camera);

		// calculate objects intersecting the picking ray
		var intersects = this.raycaster.intersectObjects(this.intersect);
		if (
			intersects.length === 1 &&
			this.mesh.material.uniforms.uStartTime.value <
				this.mesh.material.uniforms.uTime.value - 0.09
		) {
			this.mesh.material.uniforms.uStartTime.value = this.mesh.material.uniforms.uTime.value;

			return;
		}
	};
	getViewSizeAtDepth(depth = 0) {
		const fovInRadians = (this.camera.fov * Math.PI) / 180;
		const height = Math.abs(
			(this.camera.position.z - depth) * Math.tan(fovInRadians / 2) * 2
		);
		return { width: height * this.camera.aspect, height };
	}

	intro = ({
		lux = this.lux,
		tech = this.tech,
		design = this.design,
		onComplete,
	}) => {
		if (lux == null || tech == null || design == null) return;
		// let ele = document.getElementsByClassName('white')[0];

		this.onResize();
		this.lux = lux;
		this.tech = tech;
		this.design = design;
		lux.children[0].material.opacity = 0;
		tech.children[0].material.opacity = 0;
		design.children[0].material.opacity = 0;

		this.mesh.position.z = 37;
		this.uniforms.uLightZ.value = 30 + 70;

		// t1.to(this.uniforms.uLightZ, {
		// 	value: 30,
		// 	duration: 2,
		// 	ease: "power2.inOut",
		// }, '-=2');
		let t1 = gsap.timeline({
			onStart: () => {
				// ele.style.opacity = 0;
			},
			onComplete: () => {
				this.isIntroDone = true;
				if (onComplete) onComplete();
			},
		});

		t1.to(
			design.children[0].material,
			{
				opacity: 1,
				duration: 0.71,
				ease: 'power2.out',
			},
			0
		);

		t1.fromTo(
			design.position,
			{
				x: design.position.x - 5,
			},
			{
				x: design.position.x,
				duration: 0.9,
				ease: 'power2.out',
			},
			0
		);
		//
		t1.to(
			tech.children[0].material,
			{
				opacity: 1,
				duration: 0.71,
				ease: 'power2.out',
			},
			0.25
		);
		t1.fromTo(
			tech.position,
			{
				x: tech.position.x - 5,
			},
			{
				x: tech.position.x,
				duration: 0.9,
				ease: 'power2.out',
			},
			0.25
		);
		//
		t1.to(
			lux.children[0].material,
			{
				opacity: 1,
				duration: 0.71,
				ease: 'power2.out',
			},
			0.5
		);
		t1.fromTo(
			lux.position,
			{
				x: lux.position.x + 5,
			},
			{
				x: lux.position.x,
				duration: 0.9,
				ease: 'power2.out',
			},
			0.5
		);
		t1.to(this.uniforms.uDistortionFrequency, {
			value: 1,
			duration: 0.8,
			ease: 'power2.inOut',
		});
		// this.blurPostProcess.blurSize = 0.1;
		// t1.to(this.blurPostProcess, {
		// 	blurSize: 0.1,
		// 	duration: 1,
		// 	ease: 'power2.inOut',
		// });

		// t1.to(
		// 	tech.material,
		// 	{
		// 		opacity: 1,
		// 		duration: 0.5,
		// 		ease: "power2.inOut",
		// 	},
		// 	0.2
		// );
		// t1.to(
		// 	lux.material,
		// 	{
		// 		opacity: 1,
		// 		duration: 0.5,
		// 		ease: "power2.inOut",
		// 	},
		// 	0.4
		// );

		t1.to(
			this.uniforms.uDistortionFrequency,
			{
				value: 1,
				duration: 1,
				ease: 'power2.inOut',
			},
			'-=0.8'
		);
		t1.to(
			this.mesh.position,
			{
				z: 0,
				duration: 2,
				ease: 'power2.inOut',
			},
			'-=1'
		);

		t1.to(
			this.uniforms.uLightZ,
			{
				value: 30,
				duration: 2,
				ease: 'power2.inOut',
			},
			'-=2'
		);
	};
	updateDarkMode(isDarkMode) {
		let progress = 1;
		let color = this.bg.material.color;
		// this.contentScene.background = new THREE.Color(
		// 	this.options.isDarkMode ? 0xffffff : 0xffffff
		// );
		let bgValue = isDarkMode ? 0 : 1;
		// color.r = bgValue;
		// color.b = bgValue;
		// color.g = bgValue;
		gsap.to(color, {
			duration: 0.5,
			r: bgValue,
			g: bgValue,
			b: bgValue,

			ease: 'power2.inOut',
		});
		let boost = isDarkMode ? 3 : 1.5;
		gsap.to(this.mesh.material.uniforms.uIriBoost, {
			duration: 0.5,
			value: boost,
			ease: 'power2.inOut',
		});
		gsap.to(this.mesh.material.uniforms.uDarkProgress, {
			duration: 0.5,
			value: bgValue,
			ease: 'power2.inOut',
		});
		// this.contentScene.background = new THREE.Color(
		// 	isDarkMode ? 0x000000 : 0xffffff
		// );
		// this.uniforms.uIsDarkMode.value = isDarkMode;
		this.contentScene.children.forEach((obj) => {
			if (obj.userData.isBackground || obj.children[0] == null) return;
			let color = obj.children[0].material.color;
			gsap.to(color, {
				duration: 0.5,
				r: 1 - bgValue,
				g: 1 - bgValue,
				b: 1 - bgValue,
				ease: 'power2.inOut',
			});
			// color.r = 255 - bgValue;
			// color.b = 255 - bgValue;
			// color.g = 255 - bgValue;
		});
	}
	addFullscreenPlane() {
		let geometry = new PlaneBufferGeometry(1, 1, 1, 1);
		let material = new MeshBasicMaterial({
			// color: 0xff0000,
			map: this.envFBO.texture,
			depthTest: false,
			depthWrite: false,
		});
		let mesh = new Mesh(geometry, material);
		mesh.renderOrder = -1;

		this.fullscreenPlane = mesh;

		mesh.scale.set(this.viewAt0.width, this.viewAt0.height, 1);

		this.scene.add(mesh);
	}
	createBackground() {
		let geometry0 = new PlaneBufferGeometry(500, 500);
		let material0 = new MeshBasicMaterial({
			color: 0x000000,
			depthWrite: false,
			depthTest: false,
		});
		let mesh0 = new Mesh(geometry0, material0);
		mesh0.userData.isBackground = true;
		mesh0.renderOrder = -10;
		this.contentScene.add(mesh0);
		this.bg = mesh0;
	}
	setMeshScale() {
		let scale = 1;
		if (this.vp.width < 440) {
			scale = 0.8;
		}
		this.mesh.scale.set(scale, scale, scale);
	}
	init() {
		let geometry = new SphereBufferGeometry(10, 64, 64);
		let material = new PBR({
			envMap: this.blurPostProcess.bluredRenderTarget.texture,
			resolution: [this.vp.width * this.vp.dpr, this.vp.height * this.vp.dpr],
			isDarkMode: this.options.isDarkMode,
		});
		this.uniforms = material.uniforms;
		let mesh = new Mesh(geometry, material);
		mesh.renderOrder = 10;
		this.mesh = mesh;

		// this.mesh.scale.set(0.8, 0.8, 0.8);
		this.setMeshScale();
		this.mesh.position.z = 37;
		// mesh.visible = false;
		this.createBackground();
		this.intersect = [this.mesh];

		this.scene.add(mesh);

		this.addFullscreenPlane();

		// this.scene.add(mesh);
		// initTweak.call(this);
		this.addEvents();

		this.positionSpring = springu(this.mesh.position, {
			x: 0,
			y: 0,
			spring: 0.005,
			damping: 0.93,
			friction: 0.98,
		});
		this.position = { x: 0, y: 0 };

		gsap.ticker.add(this.tick);
	}
	onResize = () => {
		this.setMeshScale();
		this.uniforms.resolution.value = [
			this.vp.width * this.vp.dpr,
			this.vp.height * this.vp.dpr,
		];
		this.viewAt0 = this.getViewSizeAtDepth();
		this.fullscreenPlane.scale.set(this.viewAt0.width, this.viewAt0.height, 1);

		this.onResizeCB.forEach((cb) => cb());
	};
	setSize = (width, height, updateStyle) => {
		this.renderer.setSize(width, height, false);
	};
	updatePosition() {
		// let position = this.mesh.position;
		let x = this.position.x;
		let y = this.position.y;

		let distance = (x, y) => Math.sqrt(x * x + y * y);

		let fromMouse = { x: x - this.mouse.x, y: y - this.mouse.y };

		let fromMouseDistance = distance(fromMouse.x, fromMouse.y);
		let mouseDist = distance(this.mouse.x, this.mouse.y);
		let force = -(1 - gsap.utils.clamp(fromMouseDistance / 2, 0, 10)) * 0.1;

		if (fromMouseDistance > 0 && this.isIntroDone) {
			x += (fromMouse.x / fromMouseDistance) * force;
			y += (fromMouse.y / fromMouseDistance) * force;
		}

		let dist = distance(x, y);

		if (dist > 0) {
			x = (x / dist) * Math.min(dist, 2);
			y = (y / dist) * Math.min(dist, 0.5);
		}
		// to Home
		x += lerp(x, 0, 0.2, 0.0001);
		y += lerp(y, 0, 0.2, 0.0001);

		this.position.x = x;
		this.position.y = y;
	}
	update(t) {
		this.mesh.material.uniforms.uTime.value += 0.1 * t;
		this.mesh.material.uniforms.uMouseAmplitude.value += springuInline(
			this.mesh.material.uniforms.uMouseAmplitude,
			'value',
			this.mouseAmplitude,
			{ rest: 0.00001, spring: 0.01, damping: 0.85, friction: 0.95 }
		);

		this.updatePosition();

		this.positionSpring.update({
			x: -this.position.x * 3,
			y: -this.position.y * 3,
		});
		this.positionSpring.tick();

		let uMouse = this.mesh.material.uniforms.uMouse.value;
		let mouseSpeedX = lerp(uMouse.x, this.mouse.x, 0.1, 0.0001);
		let mouseSpeedY = lerp(uMouse.y, this.mouse.y, 0.1, 0.0001);

		if (mouseSpeedX || mouseSpeedY)
			uMouse.set(uMouse.x + mouseSpeedX, uMouse.y + mouseSpeedY);

		let mousePoint = this.mesh.material.uniforms.uMousePoint.value;
		mousePoint.lerp(this.mouseNormal, 0.04);
		// uMouse.set(
		// 	uMouse.x + lerp(uMouse.x, this.mouse.x, 0.1, 0.0001),
		// 	uMouse.y + lerp(uMouse.y, this.mouse.y, 0.1, 0.0001)
		// );
	}
	render(t) {
		this.renderer.setRenderTarget(this.envFBO);
		// this.renderer.clear();
		this.renderer.render(this.contentScene, this.textCamera);

		this.blurPostProcess.render(this.envFBO);

		this.renderer.setRenderTarget(this.aaPostProcess.renderTarget);

		// Need to clear the aa renderTarget otherwise it looks odd.
		this.renderer.clear();
		// this.renderer.render(this.contentScene, this.textCamera);
		this.renderer.clearDepth();
		this.renderer.render(this.scene, this.camera);
		this.aaPostProcess.render(this.aaPostProcess.renderTarget, null);
	}
	resize = () => {
		const canvas = this.renderer.domElement;
		let width = canvas.offsetWidth;
		let height = canvas.offsetHeight;

		if (width === this.vp.width && height === this.vp.height) return;

		this.setSize(width, height, false);
		this.camera.aspect = width / height;
		this.camera.updateProjectionMatrix();
		this.textCamera.aspect = width / height;
		this.textCamera.updateProjectionMatrix();

		this.vp.width = width;
		this.vp.height = height;

		this.envFBO.setSize(
			this.vp.width * this.vp.dpr,
			this.vp.height * this.vp.dpr
		);

		this.blurPostProcess.setSize(
			(this.vp.width * this.vp.dpr) / 2,
			(this.vp.height * this.vp.dpr) / 2
		);

		this.aaPostProcess.setSize(
			this.vp.width * this.vp.dpr,
			this.vp.height * this.vp.dpr
		);
		this.onResize();
	};
	tick = (t) => {
		let delta = this.clock.getDelta();
		// if (resizeRendererToDisplaySize(this.renderer, this.setSize)) {
		// 	const canvas = this.renderer.domElement;
		// 	this.camera.aspect = canvas.clientWidth / canvas.clientHeight;
		// 	this.camera.updateProjectionMatrix();
		// 	this.textCamera.aspect = canvas.clientWidth / canvas.clientHeight;
		// 	this.textCamera.updateProjectionMatrix();

		// 	this.vp.width = canvas.clientWidth;
		// 	this.vp.height = canvas.clientHeight;

		// 	this.envFBO.setSize(
		// 		this.vp.width * this.vp.dpr,
		// 		this.vp.height * this.vp.dpr
		// 	);

		// 	this.blurPostProcess.setSize(
		// 		(this.vp.width * this.vp.dpr) / 2,
		// 		(this.vp.height * this.vp.dpr) / 2
		// 	);

		// 	this.aaPostProcess.setSize(
		// 		this.vp.width * this.vp.dpr,
		// 		this.vp.height * this.vp.dpr
		// 	);

		// 	this.onResize();
		// }
		if (!this.disableRender) {
			this.update(delta);
			this.render(delta);
		}
		// requestAnimationFrame(this.tick);
	};
}

function resizeRendererToDisplaySize(renderer, setSize) {
	const canvas = renderer.domElement;
	const width = canvas.clientWidth;
	const height = canvas.clientHeight;
	const needResize = canvas.width !== width || canvas.height !== height;
	if (needResize) {
		setSize(width, height, false);
	}
	return needResize;
}

// let ap = new App();
// ap.init();
