// Imports
// ------
import React from 'react';
import {
	Object3D,
	Mesh,
	MeshBasicMaterial,
	DoubleSide,
	ShapeBufferGeometry,
} from '../webgl/three';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils';
import { lightDarkMode } from '@states/darkmode';

// Component
// ------
export class TextureElement extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ref: null,
			svg: null,
			isObjectCreated: false,
			needsToBeCreated: false,
		};

		this.eleRef = React.createRef();
		this.rect = null;
		this.savedObjects = {};
		this.loader = new SVGLoader();
		this.isObjectFirstMount = true;

		this.masterObject = new Object3D();
		this.masterObject.frustumCulled = false;
	}
	componentDidMount() {}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.webgl == null && this.props.webgl != null) {
			this.props.webgl.onResizeCB.push(this.resize);
			this.props.webgl.contentScene.add(this.masterObject);
			// if (this.eleRef.current) this.resize();
		}

		if (this.props.webgl && this.state.needsToBeCreated) {
			if (
				this.state.svg != null &&
				this.state.ref != null &&
				this.state.svg != prevState.svg &&
				this.state.ref != prevState.ref
			) {
				this.mountObject(this.state.svg, this.state.ref);
			}
		}
		if (this.state.ref !== prevState.ref) {
			// The element swap isn't before the resize. So, while the swaped element has it's corrent element.
			// The other elements affect by the change, don't. So, we need to resize everything.
			if (this.props.webgl) this.props.webgl.onResize();
		}
	}

	onRefChange = (node) => {
		if (node === this.eleRef.current) return;
		this.eleRef.current = node;
		this.setState({ ref: node });
		if (node !== null) {
			// For re-using old objects.
			if (node.id != null) {
				if (this.group) this.group.visible = false;
				this.masterObject.children.forEach((obj) => {
					obj.visible = false;
				});
				let object = this.savedObjects[node.id];
				if (object != null) {
					object.visible = true;
					this.group = object;
					if (this.props.webgl) this.props.webgl.onResize();
					else {
						this.resize();
					}
					return;
				}
			}
			// check if it existed.

			// loader.defaultDPI = 200;
			let svg = this.loader.parse(node.outerHTML);
			this.setState({ svg });

			this.isObjectCreated = false;
			// this.needsToBeCreated = true;
			if (this.props.webgl) {
				this.mountObject(svg, node);
				this.setState({
					needsToBeCreated: false,
				});
			} else {
				this.setState({
					needsToBeCreated: true,
				});
			}
		}
		// update texture if not null.
	};

	setBounds() {
		if (this.eleRef.current == null) return;
		let rect = this.eleRef.current.getBoundingClientRect();
		let scrollTop =
			document.documentElement.scrollTop || document.body.scrollTop;
		this.rect = {
			x: rect.x,
			y: rect.y + scrollTop,
			height: rect.height,
			width: rect.width,
		};
	}

	setSize() {
		if (this.eleRef.current == null) return;
		let webgl = this.props.webgl;
		let viewSize = this.props.webgl.viewAt0;

		let svgWidth = this.group.userData.width;
		let eleWidth = this.eleRef.current.clientWidth;

		// We only need to calculate the scale of the width and use it for both.
		let svgViewWidth = (eleWidth * viewSize.width) / webgl.vp.width;
		let scale = svgViewWidth / eleWidth;

		// The svg geometry is created with it's intial size. But it's current size
		// may change. So, scale the svg geometry's size to the element size.
		let svgToElement = eleWidth / svgWidth;
		scale = scale * svgToElement;

		this.group.scale.set(scale, -scale, 1);
		this.group.needsUpdate = true;
	}

	setPosition() {
		if (this.eleRef.current == null) return;
		let viewSize = this.props.webgl.viewAt0;
		let rect = this.rect;
		let webgl = this.props.webgl;

		// console.log(webgl.vp, window.innerHeight);

		this.group.position.set(
			-viewSize.width / 2 + (rect.x / window.innerWidth) * viewSize.width,
			viewSize.height / 2 - (rect.y / window.innerHeight) * viewSize.height,
			0
		);
	}

	resize = () => {
		this.setBounds();
		this.setSize();
		this.setPosition();
	};

	mountObject = (svg, node) => {
		this.setState({ needsToBeCreated: false });
		this.masterObject.children.forEach((obj) => {
			obj.visible = false;
		});
		let webgl = this.props.webgl;
		var paths = svg.paths;
		// Don't use a group because mesh.layers messes with things
		// var group = new THREE.Object3D();
		let mesh = new Mesh();
		for (var i = 0; i < paths.length; i++) {
			var path = paths[i];
			var material = new MeshBasicMaterial({
				color: lightDarkMode.isDark ? 0xffffff : 0x000000,
				// color: 0xff0000,
				opacity: this.isObjectFirstMount ? 0 : 1,
				transparent: true,
				side: DoubleSide,
				depthWrite: false,
				depthTest: false,
			});

			var shapes = path.toShapes(true);
			// let geo = new BufferGeometry();
			let geometries = [];
			for (var j = 0; j < shapes.length; j++) {
				var shape = shapes[j];
				var shapeGeo = new ShapeBufferGeometry(shape);
				geometries.push(shapeGeo);
			}
			let geometry = BufferGeometryUtils.mergeBufferGeometries(geometries);
			geometry.computeBoundingBox();
			// var mesh = new THREE.Mesh(geometry, material);
			mesh.geometry = geometry;
			mesh.material = material;
			mesh.frustumCulled = false;
			// mesh.layers.set(1);
			// group.add(mesh);
			let box = geometry.boundingBox;
			mesh.userData.width = box.max.x - box.min.x;
			mesh.userData.height = box.max.y - box.min.y;
		}
		if (node.id) {
			this.savedObjects[node.id] = mesh;
		}
		this.isObjectFirstMount = false;
		this.group = mesh;
		// mesh.layers.set(1);
		this.setBounds();
		this.setSize();
		this.setPosition();
		this.masterObject.add(mesh);
	};

	render() {
		return this.props.children(this.onRefChange);
	}
}
