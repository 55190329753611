// Imports
// ------
import { P, breakup } from '@tackl';
import styled, { css } from 'styled-components';

// Exports
// ------
export const sharedTextStyles = css`
	padding-bottom: 3rem;
	text-align: left;
	max-width: 17em;
	${breakup.medium`
		padding-bottom: 4rem;
		text-align: left;
	`};
`;

export const BottomText = styled(P)`
	${sharedTextStyles}
`;

export const ButtonJacket = styled.div`
	display: flex;
	justify-content: center;
`;

export const TopText = styled(P)`
	${sharedTextStyles}
`;


export const ScrollJacket = styled.div`
	position: absolute;
	bottom: 2.4rem;

	display: flex;
	width: 100%;
	justify-content: center;

	> svg {
		fill: ${props => props.theme.bc1};
	}
`;