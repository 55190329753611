// Imports
// ------
import styled from 'styled-components';
import { Section, H2 } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)``;

export const Title = styled(H2)`
    margin-bottom: 3.6rem;
`;